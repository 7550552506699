import { useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import { gePersonal } from '../features/auth/authSlice'

/**
 * setState with post callback
 */
export function useStateCallback<T>(initialState: T): [T, (state: T, cb?: (state: T) => void) => void] {
  const [state, setState] = useState(initialState)
  const cbRef = useRef<((state: T) => void) | undefined>(undefined) // init mutable ref container for callbacks

  const setStateCallback = useCallback((state: T, cb?: (state: T) => void) => {
    cbRef.current = cb
    setState(state)
  }, [])

  useEffect(() => {
    if (cbRef.current) {
      cbRef.current(state)
      cbRef.current = undefined
    }
  }, [state])

  return [state, setStateCallback]
}

/**
 * Load user profile
 */
export const useCurrentUser = () => {
  const dispatch = useAppDispatch()
  const {
    user: { profile, loggedIn },
  } = useAppSelector((state) => state.auth)

  useEffect(() => {
    if (loggedIn && !profile) {
      dispatch(gePersonal())
    }
  }, [loggedIn])

  return { user: profile }
}

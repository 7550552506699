import React from 'react'
import { Alert, AlertTitle } from '@mui/material'

export type ErrorsList = { field: string; message: string }[]

type Props = {
    errors: ErrorsList
}

const ErrorsAlert = ({ errors }: Props) => {
    if (errors.length < 1) {
        return null
    }

    return (
        <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <>
                {errors.map(({ field, message }, index) => (
                    <div key={index}>
                        <span>
                            <strong>{field}</strong>: {message}
                        </span>
                        <br />
                    </div>
                ))}
            </>
        </Alert>
    )
}

export default ErrorsAlert

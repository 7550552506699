import React from 'react'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material'
import { Button, Paper } from '@mui/material'

const st = {
    button: {
        minWidth: '24px',
        minHeight: '24px',
        height: '24px',
        width: '24px',
    },
}

type PropTypes = {
    onMove: (direction: number) => void
    hovered?: boolean
}

const MoveMarkers = ({ onMove, hovered = false }: PropTypes) => {
    if (!hovered) {
        return <></>
    }

    return (
        <Paper sx={{ width: '24px' }}>
            <Button style={st.button} variant="text" onClick={() => onMove(-1)} sx={st.button}>
                <KeyboardArrowUp />
            </Button>
            <Button style={st.button} variant="text" onClick={() => onMove(1)} sx={st.button}>
                <KeyboardArrowDown />
            </Button>
        </Paper>
    )
}

export default MoveMarkers

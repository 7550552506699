import { useAppDispatch, useAppSelector } from '../../store'
import { useEffect } from 'react'
import { fetchResourceCategories, fetchTags } from './resourcerSlice'

export const useResourceCategories = () => {
  const dispatch = useAppDispatch()
  const { categories } = useAppSelector((state) => state.resources)

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchResourceCategories())
    }
  }, [])

  return { categories }
}

export const useTags = () => {
  const dispatch = useAppDispatch()
  const { tags } = useAppSelector((state) => state.resources)
  useEffect(() => {
    dispatch(fetchTags())
  }, [])

  return tags || []
}

import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { List, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { useCategories, usePages } from '../../../features/cms/cmsHooks'
import { Page } from '../../../features/cms/cmsSlice'
import RowItem from '../../resources/items/components/RowItem'
import EditPageModal, { usePageModal } from '../CmsPageEdit/components/EditPageModal'
import { useEffect, useState } from 'react'

const CmsPages = () => {
    const navigate = useNavigate()
    const {
        state: { categoryId },
    } = useLocation()
    useCategories()
    const { pages } = usePages()
    const [viewPages, setViewPages] = useState<Page[]>([])
    useEffect(() => {
        setViewPages(pages.filter((i) => categoryId === i.categoryId))
    }, [pages, categoryId])
    const { open, page, openModal, onCloseModal } = usePageModal({})

    const handleOpenPage = (page: Page) => {
        navigate('/cms/page/', { state: { page } })
    }

    const handleEditModal = (page: Page) => {
        openModal(page)
    }

    return (
        <>
            <Box sx={{ my: 2 }}>
                <Typography variant="subtitle2" onClick={() => navigate(-1)} className="breadcrumbs" mb={3}>
                    Back to Categories
                </Typography>
                <Typography variant="h5">Applications</Typography>
            </Box>
            <List sx={{ width: '100%' }}>
                {viewPages.map((v, k) => {
                    return (
                        <RowItem
                            row={v}
                            index={k + 1}
                            key={v.id}
                            onClick={() => handleOpenPage(v)}
                            onEdit={() => handleEditModal(v)}
                        />
                    )
                })}
            </List>
            <EditPageModal page={page} onClose={onCloseModal} open={open} />
        </>
    )
}

export default CmsPages

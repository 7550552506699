import React, { useState } from 'react'
import { Box, Button } from '@mui/material'
import ModalWindow, { st } from '../../../../components/ModalWindow'
import PasswordInput from '../../../../components/PasswordInput'
import { validate, validatePasswordSchema } from '../../../../common/helpers'
import AUTH_API from '../../../../features/auth/authApi'
import { getFormData } from '../../../../common/helpers'
import { useAppDispatch } from '../../../../store'
import { errorMessage, successMessage } from '../../../../features/common/commonSlice'

export const useChangePassword = () => {
    const [opened, setOpened] = useState(false)

    const onClose = () => {
        setOpened(false)
    }

    const openModal = () => {
        setOpened(true)
    }

    return { opened, onClose, openModal }
}

type PropTypes = {
    open: boolean
    onClose: () => void
}

const ChangePasswordModal = ({ open, onClose }: PropTypes) => {
    const [errorFields, setErrorFields] = useState<string[]>([])
    const dispatch = useAppDispatch()

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
        setErrorFields([])
        const data = getFormData(e)
        const { result, errors } = validate(data, validatePasswordSchema)
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }
        await AUTH_API.updatePassword({ password: result.password })
            .then(() => dispatch(successMessage('Password changed')))
            .catch((e) => dispatch(errorMessage(e.message)))
        onClose()
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <ModalWindow open={open} title="Change Password" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <PasswordInput
                    margin="normal"
                    required
                    fullWidth
                    label="Enter your new password"
                    name="password"
                    autoFocus
                    error={hasError('password')}
                    sx={{ mb: 2 }}
                />
                <PasswordInput
                    fullWidth
                    required
                    margin="normal"
                    name="confirmPassword"
                    label="Repeat your new password"
                    error={hasError('confirmPassword')}
                />
                <Box sx={st.blockButtons}>
                    <Button variant="text" size="large" sx={{ mr: 1 }} onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                        Save changes
                    </Button>
                </Box>
            </Box>
        </ModalWindow>
    )
}

export default ChangePasswordModal

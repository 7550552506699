import React, { useRef } from 'react'
import { Box } from '@mui/material'
import ModalWindow, { ButtonsBlock } from '../../../components/ModalWindow'
import { AlertMessage } from '../types'
import TextEditor from '../../../components/TextEditor'

type PropTypes = {
    open: boolean
    onClose: () => void
    onSave: (a: any) => void
    settings: AlertMessage
}

const EditAlertModal = ({ open, onClose, settings, onSave }: PropTypes) => {
    const editorRef = useRef(null)
    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        const current = editorRef?.current
        if (current) {
            // @ts-ignore
            onSave({ message: current.getContent(), enabled: settings.enabled })
        }
        onClose()
    }

    return (
        <ModalWindow open={open} title="Edit Alert Message" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextEditor ref={editorRef} content={settings.message} tools="link" />
                <ButtonsBlock onCancel={onClose} />
            </Box>
        </ModalWindow>
    )
}

export default EditAlertModal

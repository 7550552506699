import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import AUTH_API, { UpdateUserPersonal } from './authApi'
import { resetAll } from '../common/commonSlice'

export interface User {
  id: string
  email: string
  name: string
  surname: string
  roles: string[]
}

interface AuthState {
  loading: boolean
  user: {
    loggedIn: boolean
    profile: User | null
  }
  userToken: string | null
}

const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null

const initialState: AuthState = {
  loading: false,
  user: {
    loggedIn: !!userToken,
    profile: null,
  },
  userToken,
}

export const gePersonal = createAsyncThunk('auth/gePersonal', async () => {
  const { data } = await AUTH_API.getPersonal()

  return data
})

export const updatePersonal = createAsyncThunk('auth/updatePersonal', async (params: UpdateUserPersonal) => {
  const { data } = await AUTH_API.updatePersonal(params)

  return data
})

const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState)

    builder.addCase(gePersonal.rejected, (state) => {
      state.userToken = null
      localStorage.removeItem('userToken')
      state.user.loggedIn = false
      state.user.profile = null
    })
    builder.addCase(gePersonal.fulfilled, (state, action: PayloadAction<User>) => {
      state.user.profile = action.payload
    })

    builder.addCase(updatePersonal.rejected, (state) => {})
    builder.addCase(updatePersonal.fulfilled, (state, action: PayloadAction<User>) => {
      state.user.profile = action.payload
    })
  },
  reducers: {
    login: (state, action: PayloadAction<{ userId: string; accessToken: string; refreshToken: string }>) => {
      state.userToken = action.payload.accessToken
      state.user.loggedIn = true
      localStorage.setItem('userToken', action.payload.accessToken)
      localStorage.setItem('refreshToken', action.payload.refreshToken)
    },
    logout: (state) => {
      state.userToken = null
      localStorage.removeItem('userToken')
      state.user.loggedIn = false
      state.user.profile = null
    },
  },
})

export default authSlice.reducer

export const { logout, login } = authSlice.actions

import Dashboard from './pages/dashboard/dashboard'
import Users from './pages/users/users'
import CmsCategories from './pages/cms/CmsCategories/CmsCategories'
import CmsPages from './pages/cms/CmsPages/CmsPages'
import CmsPageEdit from './pages/cms/CmsPageEdit/CmsPageEdit'
import ResourceCategories from './pages/resources/categories/ResourceCategories'
import ResourceItems from './pages/resources/items/ResourceItems'
import EditResource from './pages/resources/editResource/EditResource'
import PickedMarkets from './pages/markets/PickedMarkets/PickedMarkets'
import Featured from './pages/resources/featured/Featured'
import ResourceTags from './pages/resources/tags/ResourceTags'
import Profile from './pages/auth/profile/Profile'
import Settings from './pages/settings/Settings'

export type TitleItem = string | { [key: string]: TitleItem }

export interface Route {
    path: string
    component: any
    roles?: string[]
    title?: TitleItem
}

export const routes: Route[] = [
    {
        path: '/',
        component: <Dashboard />,
        title: 'Dashboard',
    },
    {
        path: '/users',
        roles: ['admin'],
        component: <Users />,
        title: 'Users',
    },
    {
        path: '/cms/categories',
        component: <CmsCategories />,
        title: { CMS: 'Categories' },
    },
    {
        path: '/cms/pages',
        component: <CmsPages />,
        title: { CMS: { Categories: 'Pages' } },
    },
    {
        path: '/cms/page/',
        component: <CmsPageEdit />,
        title: { CMS: { Categories: { Pages: 'Page' } } },
    },
    {
        path: '/resource/categories',
        component: <ResourceCategories />,
        title: { Resources: 'Categories' },
    },
    {
        path: '/resource/items',
        component: <ResourceItems />,
        title: { Resources: { Categories: 'Articles' } },
    },
    {
        path: '/resource/edit/:id',
        component: <EditResource />,
        title: { Resources: { Categories: { Articles: 'Article' } } },
    },
    {
        path: '/resource/featured',
        component: <Featured />,
        title: { Resources: 'Featured' },
    },
    {
        path: '/resource/topics',
        component: <ResourceTags />,
        title: { Resources: 'Topics' },
    },
    {
        path: '/picked-markets',
        component: <PickedMarkets />,
        title: 'Featured markets',
    },
    {
        path: '/account',
        component: <Profile />,
        title: 'User profile',
    },
    {
        path: '/settings',
        component: <Settings />,
        title: 'Global Settings',
    },
]

export default routes.map((route: Route) => {
    return {
        ...route,
        component: route.component,
    }
})

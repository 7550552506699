import { createAsyncThunk, createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'
import COMMON_API from './commonApi'
import { Address, Phone, WorkingHours, AlertMessage, StoreSettings, CookieSettings } from '../../pages/settings/types'

interface Stats {
  users: number
  markets: number
  properties: number
}

interface CommonState {
  loading: boolean
  errorMessage: null | string
  successMessage: null | string
  stats: Stats
  settings: { [key: string]: Address | Phone | WorkingHours | AlertMessage | CookieSettings }
}

const initialState: CommonState = {
  loading: false,
  errorMessage: null,
  successMessage: null,
  stats: {
    users: 0,
    markets: 0,
    properties: 0,
  },
  settings: {},
}

export const fetchStats = createAsyncThunk('common/fetchStats', async () => COMMON_API.getStats())

export const fetchSettings = createAsyncThunk('common/fetchSettings', async () => COMMON_API.getSettings())

export const setSettings = createAsyncThunk('common/setSettings', async (params: { settings: StoreSettings }) => {
  return COMMON_API.setSettings(params.settings)
})

const commonSlice = createSlice({
  name: 'common',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState)

    builder.addCase(fetchStats.rejected, (state) => {})
    builder.addCase(fetchStats.fulfilled, (state, action: PayloadAction<Stats>) => {
      state.stats = action.payload
    })

    builder.addCase(fetchSettings.rejected, (state) => {})
    builder.addCase(fetchSettings.fulfilled, (state, action: PayloadAction<StoreSettings>) => {
      state.settings = action.payload
    })

    builder.addCase(setSettings.rejected, (state) => {})
    builder.addCase(setSettings.fulfilled, (state) => {})
  },
  reducers: {
    toggleLoading: (state) => {
      state.loading = !state.loading
    },

    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },

    errorMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = null
      state.errorMessage = action.payload
    },

    successMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = null
      state.successMessage = action.payload
    },

    hideMessages: (state) => {
      state.successMessage = null
      state.errorMessage = null
    },
  },
})

export default commonSlice.reducer

export const { toggleLoading, errorMessage, successMessage, hideMessages, setLoading } = commonSlice.actions

export const resetAll = createAction('RESET_ALL')

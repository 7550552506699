import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import MARKETS_API, { MarketItem, PickedMarketItem } from './marketsApi'
import { resetAll } from '../common/commonSlice'

export interface MarketsState {
  loading: boolean
  error: string | undefined
  markets: MarketItem[]
  picked: PickedMarketItem[]
}

const initialState: MarketsState = {
  loading: false,
  markets: [],
  picked: [],
  error: undefined,
}

export const fetchMarkets = createAsyncThunk('markets/fetchMarkets', async () => {
  const [markets, picked] = await Promise.all([MARKETS_API.getMarkets(), MARKETS_API.getPickedMarkets()])

  return { markets, picked }
})

export const updatePickedMarkets = createAsyncThunk(
  'resources/savePickedMarkets',
  async (params: { items: PickedMarketItem[] }) => {
    return MARKETS_API.saveMarkets(params.items)
  },
)

const marketsSlice = createSlice({
  name: 'markets',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState)

    // Fetch markets and picked markets
    builder.addCase(fetchMarkets.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchMarkets.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(fetchMarkets.fulfilled, (state, action) => {
      const { markets, picked } = action.payload
      state.markets = markets
      state.picked = picked
      state.loading = false
    })

    // Save markers
    builder.addCase(updatePickedMarkets.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updatePickedMarkets.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(updatePickedMarkets.fulfilled, (state) => {
      state.loading = false
    })
  },
  reducers: {},
})

export default marketsSlice.reducer

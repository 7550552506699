export interface Address {
  address1: string
  address2: string
}

export interface AlertMessage {
  message: string
  enabled: boolean
}

export interface Phone {
  phone: string
}

export interface WorkingHours {
  monday: string
  tuesday: string
  wednesday: string
  thursday: string
  friday: string
  saturday: string
  sunday: string
}

export interface CookieSettings {
  title: string
  acceptButtonText: string
  acceptButtonEnable: string
  declineButtonText: string
  declineButtonEnable: string
  message: string
}

export const DAYS: (keyof WorkingHours)[] = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export type StoreSettings = { [key: string]: WorkingHours | Phone | AlertMessage | Address | CookieSettings }

import React from 'react'
import { capitalize } from 'lodash'
import { Box, Typography } from '@mui/material'
import { DAYS, WorkingHours } from '../types'

const CalendarLine = ({ children }: { children: string }) => (
    <Typography variant="body2" sx={{ lineHeight: '22px' }}>
        {children}
    </Typography>
)

type PropTypes = {
    hours: WorkingHours
}

const WorkingHoursBlock = ({ hours }: PropTypes) => {
    return (
        <>
            <Box sx={{ width: '127px', display: 'inline-block' }}>
                {DAYS.map((i) => (
                    <CalendarLine key={i}>{capitalize(i)}</CalendarLine>
                ))}
            </Box>
            <Box sx={{ display: 'inline-block' }}>
                {DAYS.map((i) => (
                    <CalendarLine key={i}>{hours[i]}</CalendarLine>
                ))}
            </Box>
        </>
    )
}

export default WorkingHoursBlock

import React, { useState, createContext, useContext, useEffect } from 'react';

interface NavigationData {
  currentPath?: string;
}

interface NavigationContextProps {
  navigationData: NavigationData;
  setNavigationData: React.Dispatch<React.SetStateAction<NavigationData>>;
}

const NavigationContext = createContext<NavigationContextProps>({
  navigationData: {},
  setNavigationData: () => {},
});

const useNavigation = () => useContext(NavigationContext);

interface WithNavigationWatcherProps {
  match: {
    path: string;
  };
}

function NavigationProvider(props: React.PropsWithChildren<{}>) {
  const [navigationData, setNavigationData] = useState<NavigationData>({});

  return (
    <NavigationContext.Provider
      value={{ navigationData, setNavigationData }}
      {...props}
    />
  );
}

function withNavigationWatcher<P extends WithNavigationWatcherProps>(
  Component: React.ComponentType<P>
) {
  return function WithNavigationWatcher(props: P) {
    const { path } = props.match;
    const { setNavigationData } = useNavigation();

    useEffect(() => {
      setNavigationData({ currentPath: path });
    }, [path, setNavigationData]);

    return <Component {...props} />;
  };
}

export { NavigationProvider, useNavigation, withNavigationWatcher };
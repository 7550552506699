import React from 'react'
import { Menu, MenuItem, Typography } from '@mui/material'
import { ArrowDropDown, ArrowDropUp, Logout, ManageAccounts, PersonOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useCurrentUser } from '../../common/hooks'

const st = {
    menuItem: { width: '16px', height: '16px' },
    toggleMenu: {
        display: 'inline-flex',
        padding: '6px 8px',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    menuItemIcon: { mr: '8px', verticalAlign: 'text-bottom', height: '16px', width: '16px' },
}

type PropTypes = {
    onExit: () => void
}

const ToolbarProfile = ({ onExit }: PropTypes) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { user } = useCurrentUser()

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    if (!user) {
        return null
    }

    return (
        <>
            <div
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                style={{
                    ...st.toggleMenu,
                    backgroundColor: !!anchorEl ? 'rgb(228,229,232)' : 'inherit',
                }}
            >
                <PersonOutlined sx={st.menuItem} />
                <Typography variant="body2" sx={{ fontSize: '12px', mx: 1 }}>
                    {user.name} {user.surname}
                </Typography>
                {!!anchorEl ? <ArrowDropUp sx={st.menuItem} /> : <ArrowDropDown sx={st.menuItem} />}
            </div>
            <Menu
                id="menu-appbar"
                sx={{ marginTop: '32px' }}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    onClick={() => {
                        handleClose()
                        navigate('/account')
                    }}
                >
                    <Typography variant="body2">
                        <ManageAccounts sx={st.menuItemIcon} />
                        Account settings
                    </Typography>
                </MenuItem>
                <MenuItem onClick={onExit}>
                    <Typography variant="body2">
                        <Logout sx={st.menuItemIcon} />
                        Logout
                    </Typography>
                </MenuItem>
            </Menu>
        </>
    )
}

export default ToolbarProfile

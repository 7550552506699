import z from 'zod'
import { BlockItem, BlockType } from './types'
import { validate as validateData, DataType } from '../../common/helpers'

export const getRandomId = () => new Date().getTime() + '' + Math.round(Math.random() * 10000)

const schema = z.object({
  content: z.object({
    blocks: z
      .array(
        z
          .array(
            z.object({
              type: z.nativeEnum(BlockType),
              content: z.string().min(10),
            }),
          )
          .min(1)
          .max(4),
      )
      .min(1),
  }),
})
export const validate = (data: DataType) => validateData(data, schema)

export const mapId = (data: BlockItem[][]): BlockItem[][] => {
  return data.map((row) => {
    return row.map((i) => {
      return { ...i, id: getRandomId() }
    })
  })
}

import { hhm } from '../../api/hhm'

interface UserData {
  email: string
  name: string
  surname: string
  roles: string[]
}

const USERS_API = {
  getUsers: () => hhm.get('admin/users'),

  getUser: (id: string) => hhm.get(`admin/users/${id}`),

  updateUser: (id: string, data: UserData) => {
    const { roles } = data
    return hhm.patch(`admin/users/${id}`, { roles })
  },

  deleteUser: (id: string) => hhm.delete(`admin/users/${id}`),

  inviteUser: (data: UserData) => hhm.post(`auth/invite`, data),
}

export default USERS_API

import { hhm } from '../../api/hhm'
import FormData from 'form-data'
import { Address, AlertMessage, Phone, WorkingHours, StoreSettings } from '../../pages/settings/types'

interface StatsResponse {
  users: number
  markets: number
  properties: number
}

export interface LinksResponseItem {
  type: string
  category: string
  title: string
  url: string
}

export type SettingsResponse = [{ name: string; value: WorkingHours | Phone | AlertMessage | Address }]

const COMMON_API = {
  uploadFile: async (file: File) => {
    const form = new FormData()
    form.append('file', file, file.name)
    return hhm.post('upload', form, { headers: { 'Content-Type': 'multipart/form-data' } })
  },

  getStats: async (): Promise<StatsResponse> => {
    const { data } = await hhm.get('stats')
    return data
  },

  getSettings: async (): Promise<StoreSettings> => {
    const { data } = await hhm.get<SettingsResponse>('settings')

    return data.reduce((n: object, { name, value }) => ({ ...n, [name]: value }), {})
  },

  setSettings: async (settingsItems: StoreSettings) => {
    const settings = Object.keys(settingsItems).map((i) => ({
      name: i,
      value: settingsItems[i],
    }))

    await hhm.put('settings', { settings })
  },

  fetchLinks: async () => {
    const { data }: { data: LinksResponseItem[] } = await hhm.get('links')
    return data
  },
}

export default COMMON_API

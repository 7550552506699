import React from 'react'
import { Box, Button, List, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Add } from '@mui/icons-material'
import RowItem from '../items/components/RowItem'
import { useTags } from '../../../features/resources/resourcesHooks'
import CreateOrUpdateTag from './components/CreateOrUpdateTag'
import { useModal } from '../../../components/ModalWindow'
import { TagItem } from '../editResource/EditResource'
import DeleteConfirmModal, { useDeleteControllers } from '../../../components/DeleteConfirmModal'
import { useAsyncRequestDispatch } from '../../../store'
import { removeTag } from '../../../features/resources/resourcerSlice'

interface Tag extends Partial<TagItem> {}

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

type PropTypes = {}

const ResourceTags = ({}: PropTypes) => {
    const tags = useTags()
    const navigate = useNavigate()
    const asyncDispatch = useAsyncRequestDispatch()
    const { open: modalOpened, closeModal, openModal, entity: editTag } = useModal<Tag | TagItem>()
    const { openedDeleteConfirm, openDeleteModal, closeConfirm, confirmDelete } = useDeleteControllers()

    const handleEdit = (id: string) => {
        const tag = tags.find((i) => i.id === id)
        if (tag) {
            openModal(tag)
        }
    }

    const handleDelete = (id: string) => {
        asyncDispatch(removeTag({ id }), 'Topic deleted').then()
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap, mt: 3, mb: 4 }}>
                <Typography variant="subtitle2" onClick={() => navigate(-1)} className="breadcrumbs">
                    Back to Categories
                </Typography>
                <Button variant="contained" size="large" startIcon={<Add />} onClick={() => openModal({})}>
                    Add Topic
                </Button>
            </Box>
            <List sx={{ width: '100%' }}>
                {tags.map((v, k) => {
                    return (
                        <RowItem
                            numeric={false}
                            noSubtext
                            row={v}
                            index={k + 1}
                            key={v.id}
                            onClick={() => {}}
                            onEdit={handleEdit}
                            onDelete={openDeleteModal}
                        />
                    )
                })}
            </List>
            <CreateOrUpdateTag open={modalOpened} onClose={closeModal} tag={editTag} />
            <DeleteConfirmModal
                open={openedDeleteConfirm}
                onConfirm={() => confirmDelete(handleDelete)}
                onCancel={closeConfirm}
                okButtonText="Delete Topic"
                title="Delete Topic"
                message="Are you sure you want to delete topic?"
            />
        </>
    )
}

export default ResourceTags

import React, { useState } from 'react'
import { Box, IconButton, Paper } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import st from '../styles'

type PropTypes = {
    children?: React.ReactNode
    isEditable?: boolean
    onEdit: () => void
    onDelete: () => void
}

const Container = ({ children = null, onEdit, onDelete, isEditable = true }: PropTypes) => {
    const [hover, setHover] = useState(false)
    return (
        <Box
            sx={{ ...st.contentBlock, mr: 1, ml: 1, flex: 1, p: 2, position: 'relative' }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {children}
            <Paper sx={{ display: hover ? 'block' : 'none', position: 'absolute', top: '16px', right: '16px' }}>
                {isEditable && (
                    <IconButton onClick={onEdit}>
                        <Edit />
                    </IconButton>
                )}
                <IconButton onClick={onDelete}>
                    <Delete />
                </IconButton>
            </Paper>
        </Box>
    )
}

export default Container

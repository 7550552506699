import React, { useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import { getFormData, validate, validatePasswordSchema } from '../../common/helpers'
import AUTH_API from '../../features/auth/authApi'
import { toggleLoading } from '../../features/common/commonSlice'
import { useAppDispatch } from '../../store'
import FormErrorMessage from '../../components/FormErrorMessage'

import { windowStyles as st } from './styles'
import PasswordInput from '../../components/PasswordInput'

type PropTypes = {
    title?: string
}

const UpdatePassword = ({ title = 'Update password' }: PropTypes) => {
    const [errorFields, setErrorFields] = useState<string[]>([])
    const [requestError, setRequestError] = useState<string | null>(null)
    const dispatch = useAppDispatch()
    const token = new URL(window.location.href).searchParams.get('token')

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setErrorFields([])
        const data = getFormData(event)
        const { result, errors } = validate(data, validatePasswordSchema)
        setRequestError(errors?.[0]?.message || '')
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }

        dispatch(toggleLoading())
        await AUTH_API.resetPassword({
            ...result,
            token,
        })
            .then(() => {
                window.location.href = '/'
            })
            .catch((e) => {
                const message = e?.response?.data?.message ?? 'Invalid request'
                setRequestError(message)
            })
            .finally(() => {
                dispatch(toggleLoading())
            })
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <Container component="main" maxWidth="md" sx={{ width: '680px' }}>
            <Box sx={st.mainBox}>
                <Box sx={st.boxWrap}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography component="h1" variant="h5">
                            {title}
                        </Typography>
                    </Box>
                    <img src="/logo_black.svg" alt="logo" width="100" />
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2, width: '100%' }}>
                    <PasswordInput
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        name="password"
                        autoFocus
                        sx={{ mb: 2 }}
                        error={hasError('password')}
                    />
                    <PasswordInput
                        margin="normal"
                        required
                        fullWidth
                        label="Repeat password"
                        name="confirmPassword"
                        error={hasError('confirmPassword')}
                    />
                    <Box sx={{ ...st.boxWrap, mt: 4 }}>
                        <Button type="submit" variant="contained" fullWidth size="large" disabled={false}>
                            Submit
                        </Button>
                    </Box>
                </Box>
                <FormErrorMessage message={requestError} />
            </Box>
        </Container>
    )
}

export default UpdatePassword

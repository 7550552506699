import React, { useEffect, useState } from 'react'
import z from 'zod'
import { Box, Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material'

import ModalWindow from '../../../components/ModalWindow'
import { fetchUsers, inviteUser, updateUser, User } from '../../../features/users/usersSlice'
import { useStateCallback } from '../../../common/hooks'
import { validate } from '../../../common/helpers'
import { useAppDispatch, useAsyncRequestDispatch } from '../../../store'

enum ROLES {
    contentCreator = 'content-creator',
    admin = 'admin',
}
const DEFAULT_ROLE = ROLES.contentCreator

interface UserOptional extends Partial<User> {
    roles?: string[]
}

const st = {
    blockButtons: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right', mt: 3 },
}

export const useCreateOrUpdateUser = () => {
    const [openedModal, setOpenedModal] = useState(false)
    const [user, setUser] = useStateCallback<UserOptional>({})

    const openUserModal = (editUser: UserOptional) => {
        setUser(editUser, () => {
            setOpenedModal(true)
        })
    }

    const onClose = () => {
        setUser({}, () => {
            setOpenedModal(false)
        })
    }

    return { openedModal, openUserModal, onClose, user }
}

const getSchema = (isEdit: boolean) => {
    let schema = z.object({
        role: z.nativeEnum(ROLES),
    })

    if (!isEdit) {
        schema = schema.merge(
            z.object({
                name: z.string().min(2),
                surname: z.string().min(2),
                email: z.string().email(),
            }),
        )
    }

    return schema
}

type PropTypes = {
    open: boolean
    user: UserOptional
    onClose: () => void
    isEdit?: boolean
}

const CreateOrUpdateUserModal = ({ open, user, onClose, isEdit = false }: PropTypes) => {
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()
    const [role, setRole] = React.useState<string>(DEFAULT_ROLE)
    const [editedUser, setEditedUser] = useState(user)
    const [errorFields, setErrorFields] = useState<string[]>([])
    useEffect(() => {
        setRole(user?.roles?.[0] || DEFAULT_ROLE)
        setEditedUser(user)
    }, [user])

    const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => setRole(event.target.value)

    const hasError = (field: string) => errorFields.includes(field)

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setErrorFields([])
        const form = new FormData(e.currentTarget)
        const data: { [key: string]: string | File } = {}
        form.forEach((v, k) => (data[k] = v))

        const { errors, result } = validate(data, getSchema(isEdit))
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }

        if (editedUser.id) {
            await asyncDispatch(updateUser({ ...result, id: editedUser.id, roles: [result.role] }), 'User updated')
        } else {
            await asyncDispatch(inviteUser({ ...result, roles: [result.role] }), 'User invitation sent')
        }
        await dispatch(fetchUsers())
        onClose()
    }

    return (
        <ModalWindow open={open} title={isEdit ? 'Edit User' : 'InviteUser'} width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <FormControl sx={{ mb: 2 }}>
                    <FormLabel sx={{ my: 1, color: '#9F9FA8' }} focused={false} color={undefined}>
                        Select user role
                    </FormLabel>
                    <RadioGroup row value={role} name="role" onChange={handleChangeRole} defaultValue={DEFAULT_ROLE}>
                        <FormControlLabel value={ROLES.admin} control={<Radio />} label="Admin" sx={{ mr: 4 }} />
                        <FormControlLabel value={ROLES.contentCreator} control={<Radio />} label="Content Editor" />
                    </RadioGroup>
                </FormControl>
                {!isEdit && (
                    <>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="First Name"
                            name="name"
                            defaultValue={editedUser.name}
                            error={hasError('name')}
                            sx={{ mb: 2 }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Last Name"
                            name="surname"
                            defaultValue={editedUser.surname}
                            error={hasError('surname')}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            name="email"
                            defaultValue={editedUser.email}
                            error={hasError('email')}
                        />
                    </>
                )}

                <Box sx={st.blockButtons}>
                    <Button variant="text" size="large" sx={{ mr: 1 }} onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                        {isEdit ? 'Save changes' : 'Send Invite'}
                    </Button>
                </Box>
            </Box>
        </ModalWindow>
    )
}

export default CreateOrUpdateUserModal

import React, { forwardRef, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import COMMON_API from '../features/common/commonApi'

const DEFAULT_TOOLS =
    'undo redo | styles blocks | bold italic | alignleft aligncenter alignright alignjustify | link image numlist bullist'
// const EDITOR_API_KEY = 'gdge2akhhbrgw1li2n0d69jje06s80memyg5j6pny6j3x517'
const EDITOR_API_KEY = 'cezxr2j9wvtyul7qys2t6sqy0m1qsefc8tw8pepuv9t4z4c7'

type PropTypes = { content?: string; tools?: string, height?: number }

export const TextEditor = forwardRef(({ content = '', tools = DEFAULT_TOOLS, height=400 }: PropTypes, ref) => {
    useEffect(() => {
        const handler = (e: any) => {
            if (e.target.closest('.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root') !== null) {
                e.stopImmediatePropagation()
            }
        }
        document.addEventListener('focusin', handler)
        return () => document.removeEventListener('focusin', handler)
    }, [])

    return (
        <Editor
            // @ts-ignore
            onInit={(evt, editor) => (ref.current = editor)}
            apiKey={EDITOR_API_KEY}
            init={{
                plugins: 'link image lists code',
                menubar: false,
                toolbar: tools,
                height,
                style_formats: [{ title: 'Red text', inline: 'span', styles: { color: '#ff0000' }, classes:['red-text'] }],
                relative_urls: true,
                link_title: false,
                link_list: async (success: any) => {
                    const rawLinks = await COMMON_API.fetchLinks()
                    const links = rawLinks.reduce((n: any, { type, category, title, url }) => {
                        const key = `${type}:${category}`
                        let cat = n?.[key] ?? { title: category, menu: [] }
                        cat.menu = [...cat.menu, { title, value: url }]
                        return { ...n, [key]: cat }
                    }, {})

                    success(Object.values(links))
                },
                link_default_target: '_self',
                link_target_list: false,
                convert_urls: false,
            }}
            initialValue={content}
        />
    )
})

export default TextEditor

import React from 'react'
import { Box, Button } from '@mui/material'
import { InsertPhotoRounded, TextFieldsRounded, VideocamRounded } from '@mui/icons-material'
import { BlockType } from '../types'

type PropTypes = {
    onSetType: (type: BlockType) => void
}

const SelectTypeBlock = ({ onSetType }: PropTypes) => {
    return (
        <Box sx={{ textAlign: 'center' }}>
            <Button variant="contained" onClick={() => onSetType(BlockType.html)}>
                <TextFieldsRounded />
            </Button>
            <Button variant="contained" sx={{ ml: 1, mr: 1 }} onClick={() => onSetType(BlockType.image)}>
                <InsertPhotoRounded />
            </Button>
            <Button variant="contained" onClick={() => onSetType(BlockType.video)}>
                <VideocamRounded />
            </Button>
        </Box>
    )
}

export default SelectTypeBlock

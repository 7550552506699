import React from "react";

export const windowStyles: { [key: string]: React.CSSProperties } = {
  mainBox: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#fff',
    padding: '88px',
    borderRadius: '8px',
    width: '680px',
  },
  boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

import React, { useEffect, useState } from 'react'
import z from 'zod'
import { Box, Button, TextField, Typography } from '@mui/material'
import ModalWindow from '../../../../components/ModalWindow'
import { fetchPages, Page, updatePage } from '../../../../features/cms/cmsSlice'
import { useStateCallback } from '../../../../common/hooks'
import { validate } from '../../../../common/helpers'
import { useAppDispatch, useAsyncRequestDispatch } from '../../../../store'
import ImagePreview from '../../../resources/categories/components/ImagePreview'
import Dropzone, { ExtendFile } from '../../../../components/Dropzone'
import { UploadFileRounded } from '@mui/icons-material'
import COMMON_API from '../../../../features/common/commonApi'
import { setLoading } from '../../../../features/common/commonSlice'
import InputWithCounter from '../../../../components/InputWithCounter'

export interface PageOptional extends Partial<Page> {
    content?: any
}

const st = {
    blockButtons: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right', mt: 3 },
    uploadField: { mt: 1, color: '#9F9FA8' },
}

export const usePageModal = (originPage: PageOptional) => {
    const [page, setPage] = useStateCallback(originPage)
    const [open, setOpen] = useState(false)

    const onUpdatePage = (newPage: Page) => setPage(newPage)

    const openModal = (current: PageOptional) => {
        setPage(current)
        setOpen(true)
    }

    const onCloseModal = () => {
        setOpen(false)
    }

    return { open, page, onUpdatePage, openModal, onCloseModal }
}

const schema = z.object({
    title: z.string().min(2).max(500),
    description: z.string().max(500).nullable(),
    keywords: z.string().max(500).nullable(),
})

type PropTypes = {
    open: boolean
    page: PageOptional
    onClose: () => void
}

const EditPageModal = ({ open, page, onClose }: PropTypes) => {
    const dispatch = useAppDispatch()
    const [banner, setBanner] = useState<string | undefined | null | ExtendFile>(page.banner)
    const asyncDispatch = useAsyncRequestDispatch()
    const [errorFields, setErrorFields] = useState<string[]>([])
    useEffect(() => {
        setBanner(page.banner)
    }, [page.banner])

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setErrorFields([])
        const form = new FormData(e.currentTarget)
        const data: { [key: string]: string | File } = {}
        form.forEach((v, k) => (data[k] = v))
        const { result, errors } = validate(data, schema)
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }

        dispatch(setLoading(true))
        if (banner instanceof File) {
            const {
                data: { url },
            } = await COMMON_API.uploadFile(banner)
            result.banner = url
        } else if (banner === undefined) {
            result.banner = null
        }

        setErrorFields([])
        // Save all except content
        const { content, ...pageData } = page
        await asyncDispatch(updatePage({ id: page.id as string, data: { ...pageData, ...result } }), 'Page updated')
        await dispatch(fetchPages())
        dispatch(setLoading(false))
        onClose()
    }

    const handleUploadImage = (files: ExtendFile[]) => {
        files.length && setBanner(files[0])
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <ModalWindow open={open} title="Edit page" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Page title"
                    name="title"
                    defaultValue={page.title}
                    autoFocus
                    error={hasError('title')}
                    sx={{ mb: 2 }}
                />
                <InputWithCounter
                    fullWidth
                    margin="normal"
                    name="description"
                    label="Page description"
                    defaultValue={page.description}
                    error={hasError('description')}
                    max={500}
                />
                <InputWithCounter
                    fullWidth
                    margin="normal"
                    name="keywords"
                    label="Keywords"
                    defaultValue={page.keywords}
                    error={hasError('keywords')}
                    sx={{ my: 0 }}
                    max={500}
                />

                {!!banner ? (
                    <ImagePreview icon={banner} onDelete={() => setBanner(undefined)} />
                ) : (
                    <Box sx={st.uploadField}>
                        <Typography variant="body1">Upload Article Banner</Typography>
                        <Dropzone onChange={handleUploadImage} icon={<UploadFileRounded />} />
                    </Box>
                )}

                <Box sx={st.blockButtons}>
                    <Button variant="text" size="large" sx={{ mr: 1 }} onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                        Save changes
                    </Button>
                </Box>
            </Box>
        </ModalWindow>
    )
}

export default EditPageModal

import React, { useState } from 'react'
import { Checkbox, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import MoveMarkers from './MoveMarkers'

export type ItemType = {
    id: string
    title: string
}

type PropTypes = {
    onMove: (id: string) => (direction: number) => void
    onToggle: (value: ItemType) => (e: any) => void
    item: ItemType
    checked?: boolean
    ordered?: boolean
    sx: object
    colorized?: boolean
}

const Element = ({ title, colorized = false }: { title: string; colorized?: boolean }) => {
    return (
        <>
            {!!colorized && <span style={{ color: '#3B873E' }}>*&nbsp;</span>}
            <span>{title}</span>
        </>
    )
}

const ListItemContainer = ({
    ordered = false,
    onMove,
    onToggle,
    item,
    checked = false,
    colorized = false,
}: PropTypes) => {
    const { id, title } = item
    const [hovered, setHovered] = useState(false)

    return (
        <div onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
            <ListItem
                secondaryAction={ordered && <MoveMarkers hovered={hovered} onMove={onMove(id)} />}
                disablePadding={true}
                sx={{ color: '#64646C' }}
            >
                <ListItemButton role="listitem" onClick={onToggle(item)}>
                    <ListItemIcon>
                        <Checkbox
                            checked={checked}
                            tabIndex={-1}
                            inputProps={{ 'aria-labelledby': id }}
                            color="default"
                        />
                    </ListItemIcon>
                    <ListItemText id={id} primary={<Element title={title} colorized={colorized} />} />
                </ListItemButton>
            </ListItem>
        </div>
    )
}

export default ListItemContainer

import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Container, Divider, IconButton, List, Theme, Toolbar, Typography } from '@mui/material'
import { HolidayVillage, AutoStories, Menu, People, Poll, Settings, ViewCarousel } from '@mui/icons-material'
import { useAuth } from '../contexts/auth'
import ToolbarProfile from './components/ToolbarProfile'
import Drawer from './components/Drawer'
import AdminMenuItems, { MenuItems } from './components/AdminMenuItems'
import AppBar from './components/AppBar'
import { routes } from '../routes'
import Title from './components/Title'

// Processed all rotes list
const pages: { [key: string]: string } = routes.reduce(
    (n, { path, title }) => ({ ...n, [path.replace(':id?', ':id')]: title }),
    {},
)

const getPageTitle = (pathName: string) => {
    const path = pathName
        .toLowerCase()
        .replace(/\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/, '/:id')
    return pages?.[path] ?? 'Dashboard'
}

const menuItems: MenuItems = {
    '/': {
        icon: (selected: Boolean) => <Poll color={selected ? 'primary' : 'inherit'} />,
        text: 'Dashboard',
    },
    '/users': {
        icon: (selected: Boolean) => <People color={selected ? 'primary' : 'inherit'} />,
        text: 'Users',
        roles: ['admin'],
    },
    '/resource/categories': {
        icon: (selected: Boolean) => <AutoStories color={selected ? 'primary' : 'inherit'} />,
        text: 'Resources',
        match: /^\/resource\//,
    },
    '/cms/categories': {
        icon: (selected: Boolean) => <ViewCarousel color={selected ? 'primary' : 'inherit'} />,
        text: 'CMS',
        match: /^\/cms\//,
    },
    '/picked-markets': {
        icon: (selected: Boolean) => <HolidayVillage color={selected ? 'primary' : 'inherit'} />,
        text: 'Selected Markets',
    },
    '-': {
        isDivider: true,
        icon: () => <></>,
        text: '',
    },
    '/settings': {
        icon: (selected: Boolean) => <Settings color={selected ? 'primary' : 'inherit'} />,
        text: 'Global Settings',
    },
}

const st = {
    appBar: { color: '#222944', backgroundColor: '#fff' },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        px: [2],
    },
    mainBox: {
        backgroundColor: (theme: Theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    selectedMenu: {
        '& .Mui-selected': {
            color: '#000',
            fontWeight: 400,
        },
    },
    header: {
        '& .title': {
            color: '#9F9FA8',
            display: 'inline',
        },
        '& .title:after': {
            content: '"❯"',
            display: 'inline',
            padding: '0 22px',
        },
        '& .last': {
            color: '#222944',
            display: 'inline',
        },
    },
}

const DRAWER_KEY = 'drawerOpened'
const drawerOpened = localStorage.getItem(DRAWER_KEY) || 'open'

const AdminLayout = ({ children, user }: any) => {
    const [open, setOpen] = React.useState(drawerOpened === 'open')
    const [title, setTitle] = React.useState('Dashboard')
    const { logOutUser } = useAuth()
    const { pathname } = useLocation()
    useEffect(() => {
        setTitle(getPageTitle(pathname))
    }, [pathname])

    const navigate = useNavigate()
    const toggleDrawer = () => {
        setOpen(!open)
        localStorage.setItem(DRAWER_KEY, !open ? 'open' : 'false')
    }
    const handleLogout = () => {
        logOutUser()
        navigate('/')
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar position="absolute" open={open} sx={st.appBar}>
                <Toolbar sx={{ pr: '24px' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            marginRight: '36px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <Menu />
                    </IconButton>
                    <Box sx={{ flexGrow: 1, ...st.header }}>
                        <Title item={title} />
                    </Box>
                    <ToolbarProfile onExit={handleLogout} />
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <Toolbar sx={st.toolbar}>
                    <img src="/logo_black.svg" alt="logo" width="100" />
                    <IconButton onClick={toggleDrawer}>
                        <Menu />
                    </IconButton>
                </Toolbar>
                <Divider />
                <List component="nav" sx={{ color: '#9F9FA8', ...st.selectedMenu }}>
                    <AdminMenuItems menuItems={menuItems} userRoles={user?.profile?.roles ?? []} />
                </List>
            </Drawer>
            <Box component="main" sx={st.mainBox}>
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                    {children}
                </Container>
            </Box>
        </Box>
    )
}

export default AdminLayout

import { configureStore } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import authReducer from './features/auth/authSlice'
import commonReducer, { errorMessage, successMessage, setLoading } from './features/common/commonSlice'
import usersReducer from './features/users/usersSlice'
import cmsReducer from './features/cms/cmsSlice'
import resourcesReducer from './features/resources/resourcerSlice'
import marketsReducer from './features/markets/marketsSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    common: commonReducer,
    users: usersReducer,
    cms: cmsReducer,
    resources: resourcesReducer,
    markets: marketsReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({}).concat([]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useAsyncRequestDispatch = () => {
  const dispatch = useDispatch<AppDispatch>()

  return async (action: any, successText: string) => {
    return Promise.resolve()
      .then(() => {
        dispatch(setLoading(true))
        return dispatch(action)
      })
      .then((result) => {
        if (typeof result === 'object' && result?.error) {
          dispatch(errorMessage(result?.error?.message))
          return null
        }

        dispatch(successMessage(successText))
        return result
      })
      .catch((e) => {
        dispatch(errorMessage(e.message))
      })
      .finally(() => dispatch(setLoading(false)))
  }
}

import React, { useEffect } from 'react'
import Chip from '@mui/material/Chip'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { TagItem } from '../pages/resources/editResource/EditResource'

type ExtendTagItem = TagItem & { newItem?: boolean }

const filter = createFilterOptions<ExtendTagItem>()
const MIN_TAG_LENGTH = 3

const style = { minHeight: '42px' }

const filterUniq = (arr: ExtendTagItem[]) => {
    let found: { [key: string]: any } = {}
    return arr.reduce((n: ExtendTagItem[], v: ExtendTagItem) => {
        if (!found?.[v.id]) {
            found = { ...found, [v.id]: true }
            n = n.concat(v)
        }
        return n
    }, [])
}

type PropTypes = {
    onChange: (tags: ExtendTagItem[]) => void
    availableTags: ExtendTagItem[]
    tags: ExtendTagItem[]
    onAddTag?: (tag: ExtendTagItem) => void
    sx?: object
}

const TagsSelector = ({ tags, availableTags, onChange, onAddTag, sx = {} }: PropTypes) => {
    const [value, setValue] = React.useState(tags || [])
    useEffect(() => {
        setValue(tags)
    }, [tags])

    const handleChange = (event: React.SyntheticEvent, newValue: ExtendTagItem[]) => {
        const isAddNew = newValue.find((i) => i.newItem)
        let items = filterUniq([...newValue])

        items = items.filter((i) => !i?.newItem)
        setValue(items)
        if (isAddNew) {
            typeof onAddTag === 'function' && onAddTag(isAddNew)
        } else {
            onChange(items)
        }
    }

    return (
        <Autocomplete
            multiple
            value={value}
            onChange={handleChange}
            options={availableTags}
            getOptionLabel={(option) => option.title}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip label={option.title} {...getTagProps({ index })} size="medium" variant="outlined" />
                ))
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            filterSelectedOptions={true}
            ListboxProps={{ style: { maxHeight: '160px' } }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params)
                if (
                    filtered.length === 0 &&
                    params.inputValue.length >= MIN_TAG_LENGTH &&
                    typeof onAddTag === 'function'
                ) {
                    filtered.push({
                        id: params.inputValue,
                        title: `＋ Add Topic "${params.inputValue}"`,
                        newItem: true,
                    })
                }

                return filtered
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    size="medium"
                    margin="normal"
                    label="Topics"
                    placeholder="Choose topic"
                    fullWidth
                    sx={{ ...sx, ...style }}
                    name="tags"
                />
            )}
        />
    )
}

export default TagsSelector

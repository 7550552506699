import React, { useState, useEffect } from 'react'
import z from 'zod'
import { Box, Button, TextField, Typography } from '@mui/material'
import ModalWindow from '../../../../components/ModalWindow'
import Dropzone, { ExtendFile } from '../../../../components/Dropzone'
import ImagePreview from './ImagePreview'
import { UploadFileRounded } from '@mui/icons-material'
import { validate } from '../../../../common/helpers'
import { useAppDispatch, useAsyncRequestDispatch } from '../../../../store'
import { addOrUpdateCategory, fetchResourceCategories } from '../../../../features/resources/resourcerSlice'
import COMMON_API from '../../../../features/common/commonApi'
import { useStateCallback } from '../../../../common/hooks'
import { Category } from './CategoryItem'
import { ResourceCategory } from '../../../../features/resources/resourcerSlice'
import InputWithCounter from '../../../../components/InputWithCounter'

const st = {
    blockButtons: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right', mt: 3 },
    uploadField: { mt: 3, color: '#9F9FA8' },
}

const schema = z.object({
    title: z.string().min(2).max(255),
    description: z.string().max(500).nullable(),
})

export const useEditCategory = (category: Category) => {
    const [editedCategory, setEditedCategory] = useStateCallback<Category>(category)
    const [displayEdit, setDisplayEdit] = useState(false)

    const openEditCategory = () => {
        setDisplayEdit(true)
    }

    const onCloseEdit = (category?: ResourceCategory) => {
        if (category) {
            setEditedCategory(category)
        }
        setDisplayEdit(false)
    }

    return { displayEdit, openEditCategory, setEditedCategory, editedCategory, onCloseEdit }
}

type PropTypes = {
    open: boolean
    onClose: (category?: ResourceCategory) => void
    category: any
}

const CreateOrUpdateCategoryModal = ({ open, onClose, category }: PropTypes) => {
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()
    const [errorFields, setErrorFields] = useState<string[]>([])
    const [icon, setIcon] = useState<string | undefined | ExtendFile>(category?.icon)
    useEffect(() => {
        setIcon(category?.icon)
    }, [category?.icon])

    const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrorFields([])
        const form = new FormData(event.currentTarget)
        const data: { [key: string]: string | number | File } = {}
        form.forEach((v, k) => (data[k] = v))
        const { result, errors } = validate(data, schema)

        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }

        if (icon instanceof File) {
            const {
                data: { url },
            } = await COMMON_API.uploadFile(icon)
            result.icon = url
        } else if (icon === undefined) {
            result.icon = null
        }

        const newCategory = { ...category, ...result }
        await asyncDispatch(
            addOrUpdateCategory({ category: newCategory }),
            !!category?.id ? 'Category updated' : 'Category created',
        )
        await dispatch(fetchResourceCategories())
        onClose(newCategory)
    }

    const hasError = (field: string) => errorFields.includes(field)

    const handleUploadImage = (files: ExtendFile[]) => {
        files.length && setIcon(files[0])
    }

    return (
        <ModalWindow
            open={open}
            title={category?.id ? 'Update Category' : 'Create New Category'}
            width="490px"
            onClose={() => onClose()}
        >
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Category Title"
                    name="title"
                    defaultValue={category.title}
                    autoFocus
                    error={hasError('title')}
                />
                <InputWithCounter
                    fullWidth
                    margin="normal"
                    name="description"
                    label="Description"
                    defaultValue={category.description}
                    error={hasError('description')}
                    sx={{ mb: 0 }}
                    max={500}
                />
                {!!icon ? (
                    <ImagePreview icon={icon} onDelete={() => setIcon(undefined)} />
                ) : (
                    <Box sx={st.uploadField}>
                        <Typography variant="body1">Upload Category Icon</Typography>
                        <Dropzone onChange={handleUploadImage} icon={<UploadFileRounded />} />
                    </Box>
                )}

                <Box sx={st.blockButtons}>
                    <Button variant="text" size="large" sx={{ mr: 1 }} onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                        Save changes
                    </Button>
                </Box>
            </Box>
        </ModalWindow>
    )
}

export default CreateOrUpdateCategoryModal

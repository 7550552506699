import React from 'react'
import { Typography } from '@mui/material'
import { AlertMessage } from '../types'
import SwitchButton from './SwitchButton'

type PropTypes = {
    data: AlertMessage
    onToggleAlertMessage: (stat: boolean) => void
}

const AlertMessageBlock = ({ data, onToggleAlertMessage }: PropTypes) => {
    const { enabled, message } = data
    const handleChangeEnabled = () => {
        onToggleAlertMessage(!enabled)
    }

    return (
        <>
            <SwitchButton onText="Turn On" offText="Turn Off" onCheck={handleChangeEnabled} checked={enabled} />
            <Typography variant="body2" mt={3}>
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </Typography>
        </>
    )
}

export default AlertMessageBlock

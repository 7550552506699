import React, { useState } from 'react'
import z from 'zod'
import { Box, TextField } from '@mui/material'
import ModalWindow, { ButtonsBlock } from '../../../components/ModalWindow'
import { getFormData, validate } from '../../../common/helpers'
import { Phone } from '../types'

const schema = z.object({
    phone: z.string().min(10),
})

type PropTypes = {
    open: boolean
    onClose: () => void
    onSave: (a: any) => void
    settings: Phone
}

const EditAddressModal = ({ open, onClose, settings: { phone }, onSave }: PropTypes) => {
    const [errorFields, setErrorFields] = useState<string[]>([])
    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        const data = getFormData(e)
        const { errors, result } = validate(data, schema)
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }
        onSave(result)
        onClose()
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <ModalWindow open={open} title="Edit Phone" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Phone"
                    name="phone"
                    defaultValue={phone}
                    autoFocus
                    error={hasError('phone')}
                    sx={{ mb: 2 }}
                />
                <ButtonsBlock onCancel={onClose} />
            </Box>
        </ModalWindow>
    )
}

export default EditAddressModal

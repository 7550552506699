const st = {
  addBlock: {
    minHeight: '24px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contentBlock: {
    minHeight: '72px',
    borderRadius: '8px',
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'inline-block',
  },
  addButton: { m: '16px 16px' },
  rowBlock: {
    width: '100%',
    display: 'flex',
    mb: 2,
    '& .red-text': {
      color: '#f00 !important',
    },
  },
  loadingStyle: {
    position: 'absolute',
    width: 'calc(100% - 64px)',
    textAlign: 'center',
    height: 'calc(100% - 64px)',
    paddingTop: '40px',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 20,
  }
}

export default st

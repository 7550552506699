import React from 'react'
import { Button, TextField, Link, Box, Typography, Container } from '@mui/material'
import { useAppDispatch } from '../../store'
import { login, gePersonal } from '../../features/auth/authSlice'
import { toggleLoading } from '../../features/common/commonSlice'
import AUTH_API from '../../features/auth/authApi'
import FormErrorMessage from '../../components/FormErrorMessage'

import { windowStyles as st } from './styles'

export default function Login() {
    const [errorMessage, setErrorMessage] = React.useState('')
    const dispatch = useAppDispatch()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrorMessage('')
        dispatch(toggleLoading())
        const data = new FormData(event.currentTarget)
        AUTH_API.login({
            username: data.get('username') as string,
            password: data.get('password') as string,
        })
            .then(({ data }) => {
                dispatch(login({ userId: data.userId, accessToken: data.accessToken, refreshToken: data.refreshToken }))
                dispatch(gePersonal())
            })
            .catch((error) => {
                const { response } = error
                const message = response?.data?.message
                console.log(error)
                setErrorMessage(message)
            })
            .finally(() => {
                dispatch(toggleLoading())
            })
    }

    return (
        <Container component="main" maxWidth="md" sx={{ width: '680px' }}>
            <Box sx={st.mainBox}>
                <Box sx={st.boxWrap}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                    </Box>
                    <img src="/logo_black.svg" alt="logo" width="100" />
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2, width: '100%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username"
                        name="username"
                        autoFocus
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Box sx={{ ...st.boxWrap, mt: 4 }}>
                        <Link href="/#/forgot-password" variant="body2" sx={{ whiteSpace: 'nowrap', mr: 6 }}>
                            Forgot password?
                        </Link>
                        <Button type="submit" variant="contained" fullWidth size="large">
                            Sign In
                        </Button>
                    </Box>
                    <FormErrorMessage message={errorMessage} />
                </Box>
            </Box>
        </Container>
    )
}

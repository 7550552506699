import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppSelector } from '../store'

export default function LoadingBackdrop() {
    const { loading } = useAppSelector((state: any) => state.common)
    return (
        <div>
            <Backdrop sx={{ color: '#fff', zIndex: 99999 }} open={loading}>
                <CircularProgress color="primary" />
            </Backdrop>
        </div>
    )
}

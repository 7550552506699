import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { fetchUsers } from './usersSlice'

export const useUsers = () => {
  const dispatch = useAppDispatch()
  const { users } = useAppSelector((state) => state.users)

  useEffect(() => {
    if (!users.length) {
      dispatch(fetchUsers())
    }
  }, [])

  return users
}

import React, { createContext, useContext, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../store'
import { login, logout } from '../features/auth/authSlice'
import { resetAll } from '../features/common/commonSlice'
import { useNavigate } from 'react-router-dom'

interface AuthContextProps {
    user: any
    logInUser: (userId: string, accessToken: string, refreshToken: string) => Promise<void>
    logOutUser: () => void
}

const AuthContext = createContext<AuthContextProps>({
    user: null,
    logInUser: async () => {},
    logOutUser: () => {},
})

const useAuth = () => useContext(AuthContext)

interface AuthProviderProps {
    children: React.ReactNode
}

function AuthProvider(props: AuthProviderProps) {
    const dispatch = useAppDispatch()
    const { user } = useAppSelector((state) => state.auth)
    const navigate = useNavigate()

    const logInUser = useCallback(
        async (userId: string, accessToken: string, refreshToken: string) => {
            await dispatch(
                login({
                    userId,
                    accessToken,
                    refreshToken,
                }),
            )
        },
        [dispatch],
    )

    const logOutUser = useCallback(() => {
        dispatch(resetAll())
        dispatch(logout())
        navigate('/')
    }, [dispatch])

    return <AuthContext.Provider value={{ user, logInUser, logOutUser }} {...props} />
}

export { AuthProvider, useAuth }

import React from 'react'
import { IconButton, Typography } from '@mui/material'
import { Page } from '../../../../features/cms/cmsSlice'
import { Edit } from '@mui/icons-material'

const st = {
    buttonEdit: {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    textHeader: { fontWeight: 'bold', mt: 3, mb: 1 },
    textContent: { color: '#64646C' },
}

type Props = { pageData: Page; onOpenEdit: () => void }

const PageDetails = ({ pageData, onOpenEdit }: Props) => {
    return (
        <div style={{ position: 'relative' }}>
            <Typography variant="h5" sx={{ mb: 1 }}>
                {pageData.title}
            </Typography>
            <Typography variant="body2" sx={st.textContent}>
                {pageData.description}
            </Typography>
            <Typography variant="body2" sx={st.textHeader}>
                Keywords
            </Typography>
            <Typography variant="body2" sx={st.textContent}>
                {pageData.keywords || 'No Keywords.'}
            </Typography>
            <Typography variant="body2" sx={st.textHeader}>
              URL
            </Typography>
            <Typography variant="body2" sx={st.textContent}>
              {pageData.fullUrl}
            </Typography>
            <IconButton onClick={onOpenEdit} sx={st.buttonEdit}>
                <Edit />
            </IconButton>
        </div>
    )
}

export default PageDetails

import React, { forwardRef, useEffect, useState } from 'react'
import { Box, Chip, IconButton, Paper, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'
import CreateOrUpdateArticle from '../../items/components/CreateOrUpdateArticle'
import { useStateCallback } from '../../../../common/hooks'
import { Resource } from '../../items/components/RowItem'
import EditContent from '../../../../components/EditContent/EditContent'
import { BlockItem } from '../../../../components/EditContent/types'

const st = {
    resourceView: {
        position: 'relative',
        p: 3,
    },
    buttonEdit: {
        position: 'absolute',
        top: 0,
        right: 0,
        margin: '24px',
    },
    textHeader: { fontWeight: 'bold', mt: 3, mb: 1 },
    textContent: { color: '#64646C' },
}

type PropTypes = {
    resource: Resource
    onSave?: (id: string) => void
}

const EditResourceForm = forwardRef(({ resource, onSave }: PropTypes, ref) => {
    const [blocks, setBlocks] = useState<BlockItem[][]>(resource.content?.blocks ?? [])
    const [openEditModal, setOpenEditModal] = useState(false)
    const [editResource, setEditResource] = useStateCallback<Resource>({})

    const handleOpenEditModal = (isOpen: boolean, resource: Resource = {}) => {
        setEditResource(resource, () => {
            setOpenEditModal(isOpen)
        })
    }

    const getBlocks = () => blocks
    useEffect(() => {
        // @ts-ignore
        ref.current = () => getBlocks()
    }, [blocks, getBlocks])

    const handleChangeBlocks = (blocks: BlockItem[][]) => {
        setBlocks(blocks)
    }

    return (
        <React.Fragment>
            <Paper sx={st.resourceView}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                    {resource.title}
                </Typography>
                <Typography variant="body2" sx={st.textContent}>
                    {resource.description}
                </Typography>
                <Typography variant="body2" sx={st.textHeader}>
                    Keywords
                </Typography>
                <Typography variant="body2" sx={st.textContent}>
                    {resource.keywords || 'No Keywords.'}
                </Typography>
                <Typography variant="body2" sx={st.textHeader}>
                    URL
                </Typography>
                <Typography variant="body2" sx={st.textContent}>
                    {resource.fullUrl}
                </Typography>
                <Typography variant="body2" sx={st.textHeader}>
                    Topics
                </Typography>
                <Box>
                    {(resource?.tags ?? []).map((tag) => (
                        <Chip key={tag.id} label={tag.title} variant="outlined" sx={{ mr: 1 }} />
                    ))}
                </Box>
                <IconButton onClick={() => handleOpenEditModal(true, resource)} sx={st.buttonEdit}>
                    <Edit />
                </IconButton>
            </Paper>

            <Box sx={{ mt: 4 }}>
                <EditContent blocks={blocks} onChange={handleChangeBlocks} />
            </Box>

            <CreateOrUpdateArticle
                open={openEditModal}
                resource={editResource}
                onClose={() => handleOpenEditModal(false)}
                onUpdate={onSave}
            />
        </React.Fragment>
    )
})

export default EditResourceForm

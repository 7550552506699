import React from 'react'
import { Box } from '@mui/material'
import Grid from '@mui/material/Grid'
import CardItem from './components/CardItem'
import { Group, Villa, HolidayVillage } from '@mui/icons-material'
import { useStats } from '../../features/common/commonHooks'

const st = {
    cardIcon: {
        height: '32px',
        width: '32px',
        color: '#F15E75',
    },
}

const Dashboard = () => {
    const { stats } = useStats()
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                    <CardItem icon={<Group sx={st.cardIcon} />} value={stats.users} title="Users" />
                </Grid>
                <Grid item xs={6} md={4}>
                    <CardItem icon={<Villa sx={st.cardIcon} />} value={stats.properties} title="Properties" />
                </Grid>
                <Grid item xs={6} md={4}>
                    <CardItem icon={<HolidayVillage sx={st.cardIcon} />} value={stats.markets} title="Markets" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard

import React from 'react'
import z, { ZodError, ZodSchema } from 'zod'
import { startCase } from 'lodash'

export type DataType = { [key: string]: any }

export type ErrorsItem = { field: string; message: string }

/**
 * User for validate password complexity and match with confirm
 */
export const validatePasswordSchema = z
  .object({
    password: z
      .string()
      .refine(
        (v) => !!v.match(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/)?.[0].match(/^[A-Za-z0-9]{8,50}$/),
        'Password requires: 8+ characters, 1 lowercase, 1 uppercase, 1 numeric',
      ),
    confirmPassword: z.string(),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({ code: 'custom', message: 'The passwords do not match', path: ['confirmPassword'] })
    }
  })

/**
 * Validate zod schema and build get errors fields on error
 */
export const validate = (data: DataType, schema: ZodSchema) => {
  try {
    const result = schema.parse(data)

    return { result, errors: null }
  } catch (e) {
    let errors: ErrorsItem[] = []
    if (e instanceof ZodError) {
      errors = e.errors.map((i) => ({
        field: i.path[0] as string,
        message: i.message as string,
      }))
    }

    return { result: null, errors }
  }
}

/**
 * Join user roles to string and Capitalize it
 */
export const prettifyUserRoles = (roles: string[]) => {
  return roles.map((i: string) => startCase(i)).join(', ')
}

/**
 * Get data from submit form event
 */
export const getFormData = (e: React.FormEvent<HTMLFormElement>) => {
  e.preventDefault()
  const form = new FormData(e.currentTarget)
  const data: { [key: string]: string | File } = {}
  form.forEach((v, k) => (data[k] = v))

  return data
}

import React, { useEffect, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TransferList, { useTransferList, ItemType } from '../../../components/TransformList/TransferList'
import { fetchFeatured, setFeatured, fetchAllResources, ResourceItem } from '../../../features/resources/resourcerSlice'
import { useAppDispatch, useAppSelector, useAsyncRequestDispatch } from '../../../store'
import { useResourceCategories } from '../../../features/resources/resourcesHooks'

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

type PropTypes = {}

const Featured = ({}: PropTypes) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()
    const { categories } = useResourceCategories()
    const [allArticles, setAllArticles] = useState<ItemType[]>([])

    useEffect(() => {
        dispatch(fetchFeatured())
        dispatch(fetchAllResources()).then(({ payload }) => {
            setAllArticles(payload as ResourceItem[])
        })
    }, [])

    const { featuredCategories, featuredItems } = useAppSelector((state) => state.resources)
    const { selected: selectedArticles, onChangeSelected: onChangeSelectedArticles } = useTransferList(featuredItems)
    const { selected: selectedCategories, onChangeSelected: onChangeSelectedCategories } =
        useTransferList(featuredCategories)

    useEffect(() => {
        onChangeSelectedCategories(featuredCategories)
        onChangeSelectedArticles(featuredItems)
    }, [featuredCategories, featuredItems])

    const handleSave = () => {
        asyncDispatch(
            setFeatured({ featuredCategories: selectedCategories, featuredItems: selectedArticles }),
            'Featured items saved',
        ).then()
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap, mt: 3, mb: 3 }}>
                <Typography variant="subtitle2" onClick={() => navigate(-1)} className="breadcrumbs">
                    Back to Categories
                </Typography>

                <Box>
                    <Button size="large" variant="contained" onClick={handleSave}>
                        Save Featured
                    </Button>
                </Box>
            </Box>

            <TransferList
                items={categories}
                selected={selectedCategories}
                onChangeSelected={onChangeSelectedCategories}
                title="Featured Categories On Home Page and Resources Page"
                leftTitle="Categories"
                rightTitle="Featured Categories"
                colorizeItems={3}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', color: 'rgb(76, 175, 80)', mt: 1 }}>
                <Typography variant="body2">The first 3 categories will be displayed on the Home page</Typography>
            </Box>

            <Box sx={{ mt: 6 }} />
            <TransferList
                items={allArticles}
                selected={selectedArticles}
                onChangeSelected={onChangeSelectedArticles}
                leftTitle="Articles"
                rightTitle="Featured Articles"
                title="Featured Articles On Resources Page"
            />
        </>
    )
}

export default Featured

import React from 'react'
import { Box, Button } from '@mui/material'
import { getRandomId } from './helpers'
import Row from './components/Row'
import Block from './components/Block'
import EditContentModal from './components/EditContentModal'
import { BlockType, BlockItem, AddPosition } from './types'

const MAX_ROW_ITEMS = 4

type PropsTypes = {
    blocks: BlockItem[][]
    onChange: (blocks: BlockItem[][]) => void
}

const EditContent = ({ blocks, onChange }: PropsTypes) => {
    const [openModal, setOpenModal] = React.useState(false)
    const [currentBlock, setCurrentBlock] = React.useState<BlockItem>()

    const buildBlock = () => ({ type: null, id: getRandomId() })

    const handleCloseModal = () => setOpenModal(false)

    const handleSetType = (type: BlockType, row: number, col: number) => {
        const newBlocks = [...blocks]
        newBlocks[row][col] = { ...newBlocks[row][col], type }
        onChange(newBlocks)
        setCurrentBlock(newBlocks[row][col])
        setOpenModal(true)
    }

    const handleAddRow = () => {
        onChange([...blocks, [{ type: null, id: getRandomId() }]])
    }

    const handleAddBlock = (position: AddPosition, row: number) => {
        const blocksRow = [...blocks[row]]
        if (position === AddPosition.before) {
            blocksRow.unshift(buildBlock())
        } else {
            blocksRow.push(buildBlock())
        }
        const newBlocks = [...blocks]
        newBlocks[row] = blocksRow
        onChange(newBlocks)
    }

    const handleDeleteBlock = (row: number, col: number) => {
        const newBlocks = [...blocks]
        const blocksRow = [...blocks[row]]
        if (blocksRow.length === 1) {
            newBlocks.splice(row, 1)
            onChange(newBlocks)
            return
        }
        blocksRow.splice(col, 1)
        newBlocks[row] = blocksRow
        onChange(newBlocks)
    }

    const handleEditBlock = (row: number, col: number) => {
        const block = blocks[row][col]
        setCurrentBlock(block)
        setOpenModal(true)
    }

    const handleEditContent = (block: BlockItem, value: string) => {
        outer: for (let i in blocks) {
            for (let j in blocks[i]) {
                const { id } = blocks[i][j]
                if (id === block.id) {
                    const newBlocks = [...blocks]
                    newBlocks[i][j] = { ...newBlocks[i][j], content: value }
                    break outer
                }
            }
        }
        setOpenModal(false)
    }

    return (
        <>
            {blocks.map((row, i) => {
                // If row blocks > 2 hide add block buttons
                const enableAdd = row.length < MAX_ROW_ITEMS
                return (
                    <Row key={i} isAdd={enableAdd} onAddBlock={(pos) => handleAddBlock(pos, i)}>
                        {row.map((item, j) => {
                            const { id } = item
                            return (
                                <Block
                                    key={id}
                                    block={item}
                                    onEdit={() => handleEditBlock(i, j)}
                                    onDelete={() => handleDeleteBlock(i, j)}
                                    onSetType={(t: BlockType) => handleSetType(t, i, j)}
                                />
                            )
                        })}
                    </Row>
                )
            })}
            <Box>
                <Button variant="contained" fullWidth onClick={handleAddRow}>
                    Add Row
                </Button>
            </Box>
            {currentBlock && (
                <EditContentModal
                    open={openModal}
                    block={currentBlock}
                    onClose={handleCloseModal}
                    onSave={(value) => handleEditContent(currentBlock, value)}
                />
            )}
        </>
    )
}

export default EditContent

import React, { useState, useEffect } from 'react'
import z from 'zod'
import { Box, Button, TextField } from '@mui/material'
import ModalWindow from '../../../../components/ModalWindow'
import { validate } from '../../../../common/helpers'
import TagsSelector from '../../../../components/TagsSelector'
import { TagItem } from '../../editResource/EditResource'
import { Resource } from './RowItem'
import { useAppDispatch, useAsyncRequestDispatch } from '../../../../store'
import {
    addOrUpdateResource,
    addTag,
    fetchResourceCategories,
    fetchTags,
} from '../../../../features/resources/resourcerSlice'
import { useTags } from '../../../../features/resources/resourcesHooks'
import { errorMessage } from '../../../../features/common/commonSlice'
import InputWithCounter from '../../../../components/InputWithCounter'

const st = {
    blockButtons: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right', mt: 3 },
}

const schema = z.object({
    title: z.string().min(2).max(500),
    description: z.string().max(500).nullable(),
    keywords: z.string().max(500).nullable(),
    tags: z.array(
        z.object({
            id: z.string(),
            title: z.string(),
        }),
    ),
})

type PropTypes = {
    open: boolean
    onClose: () => void
    resource: Resource
    onUpdate?: (id: string) => void
}

const CreateOrUpdateArticle = ({ open = false, onClose, resource, onUpdate }: PropTypes) => {
    const [tags, setTags] = useState<TagItem[]>(resource?.tags ?? [])
    const availableTags = useTags()
    const [errorFields, setErrorFields] = useState<string[]>([])
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()

    useEffect(() => {
        setTags(resource?.tags ?? [])
    }, [resource.tags])

    const handleAddTag = async (title: string) => {
        // @ts-ignore
        const { payload, error } = await dispatch(addTag({ title }))
        if (error) {
            return dispatch(errorMessage(error.message))
        }
        if (payload) {
            await dispatch(fetchTags())
            setTags([...tags, payload])
        }
    }

    const handleSave = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setErrorFields([])
        const form = new FormData(event.currentTarget)
        const data: { [key: string]: string | number | File } = {}
        form.forEach((v, k) => (data[k] = v))
        const { result, errors } = validate({ ...data, tags }, schema)

        if (errors) {
            setErrorFields(errors.map((i) => i.field))
        } else {
            const { payload } = await asyncDispatch(
                addOrUpdateResource({ resource: { ...resource, ...result } }),
                resource?.id ? 'Article updated' : 'Article created',
            )
            dispatch(fetchResourceCategories())
            typeof onUpdate === 'function' && onUpdate(payload.id)
            handleClose()
        }
    }

    const hasError = (field: string) => errorFields.includes(field)

    const handleUpdateTags = (newTags: TagItem[]) => {
        setTags(newTags)
    }

    const handleClose = () => {
        onClose()
        setTags([])
    }

    const title = resource?.id ? 'Update Article' : 'Create New Article'

    return (
        <ModalWindow open={open} title={title} width="800px" onClose={handleClose}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Article Title"
                    name="title"
                    defaultValue={resource?.title}
                    error={hasError('title')}
                />
                <InputWithCounter
                    fullWidth
                    margin="normal"
                    name="description"
                    label="Article description"
                    defaultValue={resource?.description}
                    error={hasError('description')}
                    max={500}
                />
                <InputWithCounter
                    fullWidth
                    margin="normal"
                    name="keywords"
                    label="Keywords"
                    defaultValue={resource?.keywords}
                    error={hasError('keywords')}
                    sx={{ mt: 0 }}
                    max={500}
                />
                <TagsSelector
                    sx={{ mt: 0 }}
                    tags={tags}
                    availableTags={availableTags}
                    onChange={handleUpdateTags}
                    onAddTag={({ id }) => handleAddTag(id)}
                />
                <Box sx={st.blockButtons}>
                    <Button variant="text" size="large" sx={{ mr: 1 }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                        {resource?.id ? 'Update article' : 'Create article'}
                    </Button>
                </Box>
            </Box>
        </ModalWindow>
    )
}

export default CreateOrUpdateArticle

import React from 'react'
import { Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { useLocation } from 'react-router-dom'

export interface MenuItems {
    [key: string]: {
        icon: (selected: Boolean) => React.ReactNode
        text: string
        disabled?: boolean
        isDivider?: boolean
        roles?: string[]
        match?: RegExp
    }
}

const isSelected = (currentPage: string, path: string, { match }: { match?: RegExp | undefined }) => {
    if (match) {
        return match.test(currentPage)
    }

    return currentPage === path
}

interface AdminMenuItemsProps {
    menuItems: MenuItems
    userRoles?: string[]
}

const AdminMenuItems = ({ menuItems, userRoles }: AdminMenuItemsProps) => {
    const { pathname } = useLocation()

    return (
        <React.Fragment>
            {Object.entries(menuItems).map(([key, value]) => {
                if (value.isDivider) {
                    return <Divider key={key} sx={{ my: 2 }} />
                }

                if (userRoles && value.roles && !userRoles.some((i) => value.roles?.includes(i))) {
                    return null
                }
                const selected = isSelected(pathname, key, value)

                return (
                    <ListItemButton href={`/#${key}`} key={key} selected={selected} disabled={value.disabled}>
                        <ListItemIcon>{value.icon(selected)}</ListItemIcon>
                        <ListItemText primary={value.text} />
                    </ListItemButton>
                )
            })}
        </React.Fragment>
    )
}

export default AdminMenuItems

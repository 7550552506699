import React from 'react'
import { Button } from '@mui/material'

const st = {
    button: {
        minWidth: '54px',
        minHeight: '54px',
    },
}

type PropTypes = {
    disabled?: boolean
    onClick: () => void
    children?: React.ReactElement
}

const MoveButton = ({ disabled = false, onClick, children }: PropTypes) => {
    return (
        <Button sx={{ my: 0.5 }} style={st.button} variant="contained" onClick={onClick} disabled={disabled}>
            {children}
        </Button>
    )
}

export default MoveButton

import React, { ChangeEvent, useState } from 'react'
import { TextField, TextFieldProps } from '@mui/material'

const InputWithCounter = (props: TextFieldProps & { max: number }) => {
    const defaultLength = (String(props.defaultValue || '') || '').length
    const [counter, setCounter] = useState(defaultLength || 0)
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCounter(e.target.value.length)
        typeof props.onChange === 'function' && props.onChange(e)
    }

    return (
        <TextField
            {...props}
            type="text"
            helperText={`${counter}/${props.max}`}
            FormHelperTextProps={{ sx: { textAlign: 'right' } }}
            onChange={handleChange}
            error={props.error || props.max < counter}
            sx={{ ...(props?.sx ?? {}), '&.MuiFormHelperText-root .MuiFormHelperText-filled': { textAlign: 'right' } }}
        />
    )
}

export default InputWithCounter

import React from 'react'
import { CardHeader, List, Paper } from '@mui/material'
import ListItemContainer, { ItemType } from './ListItemContainer'

const panelStyles: React.CSSProperties = {
    height: 324,
    overflow: 'auto',
}

type PropTypes = {
    items: readonly ItemType[]
    handleToggle: (value: ItemType) => (e: any) => void
    checked: string[]
    title: string
    subtitle?: string
    ordered?: boolean
    onMove?: (id: string, direction: number) => void
    colorizeItems?: number
}

const CustomList = ({
    items,
    handleToggle,
    checked,
    title,
    ordered = false,
    onMove,
    subtitle,
    colorizeItems = 0,
}: PropTypes) => {
    const handleMove = (id: string) => (direction: number) => {
        if (typeof onMove === 'function') {
            onMove(id, direction)
        }
    }

    return (
        <Paper sx={panelStyles}>
            {title && (
                <CardHeader
                    sx={{ px: 3, pt: 3, pb: !!subtitle ? 0 : 3 }}
                    title={title}
                    titleTypographyProps={{ fontSize: '16px', fontWeight: 700 }}
                    subheader={subtitle}
                />
            )}

            <List dense component="div" role="list">
                {items.map((item, index) => {
                    return (
                        <ListItemContainer
                            sx={{ color: '#C77700'}}
                            key={item.id}
                            item={item}
                            ordered={ordered}
                            onToggle={handleToggle}
                            onMove={handleMove}
                            checked={checked.includes(item.id)}
                            colorized={index < colorizeItems}
                        />
                    )
                })}
            </List>
        </Paper>
    )
}

export default CustomList

import React from 'react'
import { Box, TextField } from '@mui/material'
import ModalWindow, { ButtonsBlock } from '../../../../components/ModalWindow'
import { getFormData } from '../../../../common/helpers'
import { useAsyncRequestDispatch } from '../../../../store'
import { addTag, updateTag } from '../../../../features/resources/resourcerSlice'

type PropTypes = {
    open: boolean
    onClose: () => void
    tag: any
}

const CreateOrUpdateTag = ({ open, onClose, tag }: PropTypes) => {
    const asyncDispatch = useAsyncRequestDispatch()
    const handleSaveTag = (e: React.FormEvent<HTMLFormElement>) => {
        const data = getFormData(e)
        if (data.title) {
            if (tag.id) {
                asyncDispatch(updateTag({ id: tag.id, data }), 'Topic updated').then()
            } else {
                asyncDispatch(addTag({ title: data.title as string }), 'Topic created').then()
            }
            onClose()
        }
    }

    return (
        <ModalWindow open={open} title={tag?.id ? 'Edit Topic' : 'Add Topic'} width="490px" onClose={() => onClose()}>
            {!!tag && (
                <>
                    <Box component="form" onSubmit={handleSaveTag} noValidate sx={{ mt: 2, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            label="Topic"
                            name="title"
                            defaultValue={tag.title}
                            autoFocus
                            error={false}
                            sx={{ mb: 2 }}
                        />
                        <ButtonsBlock onCancel={onClose} />
                    </Box>
                </>
            )}
        </ModalWindow>
    )
}

export default CreateOrUpdateTag

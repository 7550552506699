import React, { useEffect, useState } from 'react'

import { useAppDispatch, useAppSelector } from '../store'
import { Alert, Snackbar, Typography } from '@mui/material'
import { CheckCircle } from '@mui/icons-material'
import { hideMessages } from '../features/common/commonSlice'

export default function SystemMessage() {
    const { errorMessage, successMessage } = useAppSelector((state: any) => state.common)
    const [type, setType] = useState<'success' | 'error'>('error')
    const dispatch = useAppDispatch()
    useEffect(() => {
        setType('error')
    }, [errorMessage])

    useEffect(() => {
        setType('success')
    }, [successMessage])

    const handleClose = () => {
        dispatch(hideMessages())
    }

    return (
        <div>
            <Snackbar
                open={!!errorMessage || !!successMessage}
                autoHideDuration={4000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                {(successMessage || errorMessage) && (
                    <Alert
                        variant="filled"
                        onClose={handleClose}
                        icon={<CheckCircle />}
                        severity={type}
                        sx={{ width: '320px', minWidth: '150px' }}
                    >
                        {type === 'success' && (
                            <>
                                <Typography variant="body1">Success</Typography>
                                <Typography variant="body2">{successMessage}</Typography>
                            </>
                        )}

                        {type === 'error' && (
                            <>
                                <Typography variant="body1">Error</Typography>
                                <Typography variant="body2">{errorMessage}</Typography>
                            </>
                        )}
                    </Alert>
                )}
            </Snackbar>
        </div>
    )
}

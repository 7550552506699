import React, { useState, useEffect } from 'react'
import { Delete, ImageOutlined } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import { ExtendFile } from '../../../../components/Dropzone'

const st = {
    imageThumb: { width: '48px', height: '48px', marginRight: '16px' },
    imagePropsContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    fileField: {
        display: 'flex',
        mt: 3,
        color: '#9F9FA8',
        background: '#F5F5F6',
        borderRadius: '8px',
        padding: '12px',
        justifyContent: 'space-between',
    },
    textFilename: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        maxWidth: '300px',
        color: '#000'
    }
}

const getSizeText = (size: number) => {
    if (size > 1024) {
        return (size / 1024).toFixed(1) + ' KB'
    }

    return `${size} B`
}

type PropTypes = {
    onDelete: () => void
    icon: string | ExtendFile
}

const ImagePreview = ({ icon, onDelete }: PropTypes) => {
    const [thumb, setThumb] = useState(icon instanceof File ? icon.preview : icon)
    useEffect(() => {
        setThumb(icon instanceof File ? icon.preview : icon)
    }, [icon])

    return (
        <Box sx={st.fileField}>
            <Box sx={st.imagePropsContainer}>
                {thumb ? <img src={thumb} style={st.imageThumb} /> : <ImageOutlined sx={st.imageThumb} />}
                <Box>
                    <Typography variant="body1" sx={st.textFilename}>
                        {icon instanceof File ? icon.name : icon}
                    </Typography>
                    <Typography variant="body1" sx={{ color: '#9F9FA8' }}>
                        {icon instanceof File && getSizeText(icon.size)}
                    </Typography>
                </Box>
            </Box>
            <IconButton onClick={onDelete}>
                <Delete />
            </IconButton>
        </Box>
    )
}

export default ImagePreview

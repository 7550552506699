import { hhm } from '../../api/hhm'

export interface MarketItem {
  id: string
  title: string
  imageUrl?: string
}

export interface PickedMarketItem extends MarketItem {
  orderNumber: number
}

const MARKETS_API = {
  getMarkets: async (): Promise<MarketItem[]> => {
    const { data } = await hhm.get('markets')
    return data
  },

  getPickedMarkets: async (): Promise<PickedMarketItem[]> => {
    const { data } = await hhm.get('markets/picked')
    return data
  },

  saveMarkets: (items: PickedMarketItem[]) => hhm.put('markets/picked', { items }),
}

export default MARKETS_API

import React from 'react'
import { TextField } from '@mui/material'

const AddVideoForm = ({ onChange }: { onChange: (url: string) => void }) => {
    return (
        <TextField
            label="Video URL"
            type="text"
            fullWidth
            sx={{ mt: 3 }}
            onChange={(e) => {
                onChange(e.target.value)
            }}
        />
    )
}

export default AddVideoForm

import React from 'react'
import { ListItem } from '@mui/material'
import { Categories } from '../../../../features/cms/cmsSlice'
import ListItemText from '@mui/material/ListItemText'

const st = {
    listItem: {
        bgcolor: 'background.paper',
        marginBottom: '12px',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    listItemText: {
        '& .MuiListItemText-primary': {
            fontWeight: 'bold',
            marginTop: 0,
        },
        '& .MuiListItemText-secondary': {
            fontSize: '12px',
            marginTop: '8px',
        },
    },
}

type PropTypes = {
    category: Categories
    onItemClick: (category: Categories) => void
}

const CategoryItem = ({ category, onItemClick }: PropTypes) => {
    return (
        <ListItem sx={st.listItem} onClick={(e) => onItemClick(category)}>
            <ListItemText primary={category.title} secondary={`${category.pagesCount} pages`} sx={st.listItemText} />
        </ListItem>
    )
}

export default CategoryItem

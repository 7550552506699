import React from 'react'
import { Routes, Route } from 'react-router-dom'
import routes, { Route as RouteType } from './routes'

export default ({ user }: any) => {
    const userRoles = user?.profile?.roles ?? []
    return (
        <>
            <Routes>
                {routes.map(({ path, component, roles }: RouteType) => {
                    if (userRoles && Array.isArray(roles) && !userRoles.some((i: string) => roles.includes(i))) {
                        return null
                    }

                    // @ts-ignore
                  return <Route key={path} path={path} element={component} text="example" />
                })}
            </Routes>
        </>
    )
}

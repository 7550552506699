import React from 'react'
import { Typography } from '@mui/material'

type PropTypes = {
    src?: string
    alt?: string
}

const ImageBlock = ({ src, alt }: PropTypes) => {
    if (src) {
        return <img src={src} alt={alt} style={{ maxWidth: '100%', maxHeight: '100%' }} />
    }

    return <Typography>No image selected</Typography>
}

export default ImageBlock

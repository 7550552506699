import React, { useState } from 'react'
import { Box, Button, List, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

import { useResourceCategories } from '../../../features/resources/resourcesHooks'
import CategoryItem, { Category } from './components/CategoryItem'
import CreateOrUpdateCategoryModal from './components/CreateOrUpdateCategoryModal'
import { useStateCallback } from '../../../common/hooks'
import {useAppDispatch, useAsyncRequestDispatch} from '../../../store'
import { fetchResourceCategories, removeCategory } from '../../../features/resources/resourcerSlice'
import DeleteConfirmModal, { useDeleteControllers } from '../../../components/DeleteConfirmModal'

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

const ResourceCategories = () => {
    const { openedDeleteConfirm, openDeleteModal, closeConfirm, confirmDelete } = useDeleteControllers()
    const { categories } = useResourceCategories()
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()
    const navigate = useNavigate()
    const [openEditModal, setOpenEditModal] = useState(false)
    const [editCategory, setEditCategory] = useStateCallback<Category>({})

    const handleItemClick = (category: Category) => {
        navigate('/resource/items', { state: { category } })
    }

    const handleAdd = () => {
        setEditCategory({}, () => {
            setOpenEditModal(true)
        })
    }

    const handleEdit = (id: string) => {
        if (Array.isArray(categories)) {
            const category = categories.find((i) => i?.id === id) || {}
            setEditCategory(category, () => {
                setOpenEditModal(true)
            })
        }
    }

    const handleCloseEdit = () => {
        setEditCategory({}, () => {
            setOpenEditModal(false)
        })
    }

    const onDeleteConfirm = async (categoryId: string) => {
        await asyncDispatch(removeCategory({ categoryId }), 'Category deleted')
        await dispatch(fetchResourceCategories())
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap, mt: 4, mb: 4 }}>
                <Typography component="h1" variant="h5">
                    Categories
                </Typography>
                <Box>
                    <Button
                      size="large"
                      variant="outlined"
                      sx={{ mr: 2 }}
                      onClick={() => navigate('/resource/topics')}
                    >
                        Topics
                    </Button>
                    <Button
                        size="large"
                        variant="outlined"
                        sx={{ mr: 2 }}
                        onClick={() => navigate('/resource/featured')}
                    >
                        Featured
                    </Button>
                    <Button variant="contained" size="large" startIcon={<Add />} onClick={handleAdd}>
                        Add Category
                    </Button>
                </Box>
            </Box>
            <List sx={{ width: '100%' }}>
                {categories.map((v) => {
                    return (
                        <CategoryItem
                            category={v}
                            key={v.id}
                            onItemClick={handleItemClick}
                            onEdit={handleEdit}
                            onDelete={openDeleteModal}
                        />
                    )
                })}
            </List>

            <CreateOrUpdateCategoryModal open={openEditModal} onClose={handleCloseEdit} category={editCategory} />
            <DeleteConfirmModal
                open={openedDeleteConfirm}
                onConfirm={() => confirmDelete(onDeleteConfirm)}
                onCancel={closeConfirm}
            />
        </>
    )
}

export default ResourceCategories

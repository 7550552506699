import { useAppDispatch, useAppSelector } from '../../store'
import { useEffect } from 'react'
import { fetchCategories, fetchPages } from './cmsSlice'

export const useCategories = () => {
  const dispatch = useAppDispatch()
  const { categories } = useAppSelector((state) => state.cms)

  useEffect(() => {
    if (!categories.length) {
      dispatch(fetchCategories())
    }
  }, [])

  return { categories }
}

export const usePages = () => {
  const dispatch = useAppDispatch()
  const { pages } = useAppSelector((state) => state.cms)
  useEffect(() => {
    if (!pages.length) {
      dispatch(fetchPages())
    }
  }, [])

  return { pages }
}

import { hhm } from '../../api/hhm'

interface LoginData {
  username: string
  password: string
}

interface ForgotEmailData {
  email: string
}

interface ResetPasswordData {
  token: string
  password: string
}

interface UpdatePasswordData {
  password: string
}

export interface UpdateUserPersonal {
  name: string,
  surname: string,
}

const AUTH_API = {
  login: (data: LoginData) => hhm.post('auth/login', data),
  forgotPassword: (data: ForgotEmailData) => hhm.post('auth/forgot-password', data),
  resetPassword: (data: ResetPasswordData) => hhm.post('auth/reset-password', data),
  updatePassword: (data: UpdatePasswordData) => hhm.post('auth/update-password', data),
  getPersonal: () => hhm.get('auth/me'),
  updatePersonal: (data: UpdateUserPersonal) => hhm.post('auth/me', data),
}

export default AUTH_API

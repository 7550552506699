import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'

const style: React.CSSProperties = {
    padding: '30px',
    border: '1px dashed #ccc',
    color: '#aaa',
    textAlign: 'center',
    cursor: 'pointer'
}

const thumbsContainer: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
}

const thumb: React.CSSProperties = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
}

const thumbInner: React.CSSProperties = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
}

const img: React.CSSProperties = {
    display: 'block',
    width: 'auto',
    height: '100%',
}

export type ExtendFile = File & { preview: string }

type PropTypes = {
    onChange: (file: ExtendFile[]) => void
    icon?: React.ReactElement
}

const Dropzone = ({ onChange, icon }: PropTypes) => {
    const [files, setFiles] = useState([])
    const { getRootProps, getInputProps } = useDropzone({
        accept: { 'image/*': [] },
        onDrop: (acceptedFiles) => {
            // @ts-ignore
            setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })))
            // @ts-ignore
            onChange(acceptedFiles)
        },
    })

    useEffect(() => {
        // @ts-ignore
        return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
    }, [])

    const thumbs = files.map((file) => (
        // @ts-ignore
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    // @ts-ignore
                    src={file.preview}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        // @ts-ignore
                        URL.revokeObjectURL(file.preview)
                    }}
                />
            </div>
        </div>
    ))

    return (
        <>
            <div {...getRootProps()} style={style}>
                <input {...getInputProps()} />
                {!!icon && icon}
                <p style={{ margin: 0, padding: 0 }}>Drag 'n' drop some files here, or click to select files</p>
            </div>
            <aside style={thumbsContainer}>{thumbs}</aside>
        </>
    )
}

export default Dropzone

import React, { useEffect } from 'react'
import { useMarkets } from '../../../features/markets/marketsHooks'
import { Button, Grid } from '@mui/material'
import { useAsyncRequestDispatch } from '../../../store'
import { updatePickedMarkets } from '../../../features/markets/marketsSlice'
import TransferList, { useTransferList } from '../../../components/TransformList/TransferList'

const PickedMarkets = () => {
    const { markets, picked } = useMarkets()
    const asyncDispatch = useAsyncRequestDispatch()
    const { selected, onChangeSelected } = useTransferList(picked)
    useEffect(() => {
        onChangeSelected(picked)
    }, [picked])

    const handleSave = () => {
        const newItems = selected.map((v, k) => ({ ...v, marketId: v.id, orderNumber: k }))
        asyncDispatch(updatePickedMarkets({ items: newItems }), 'Featured markets saved').then()
    }

    return (
        <>
            <Grid container spacing={1} sx={{ marginBottom: '20px' }}>
                <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button type="submit" variant="contained" size="large" onClick={handleSave}>
                        Save Markets
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    {!!markets.length && (
                        <TransferList
                            items={markets}
                            selected={selected}
                            onChangeSelected={onChangeSelected}
                            leftTitle="All Markets"
                            rightTitle="Selected markets"
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}

export default PickedMarkets

import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import AlertMessageBlock from './components/AlertMessageBlock'
import WorkingHoursBlock from './components/WorkingHoursBlock'
import SettingsContainer from './components/SettingsContainer'
import OfficeAddressBlock from './components/OfficeAddressBlock'
import CookieSettingBlock from './components/CookieSettingBlock'
import PhoneBlock from './components/PhoneBlock'
import EditAddressModal from './components/EditAddressModal'
import EditPhoneModal from './components/EditPhoneModal'
import EditWorkingHoursModal from './components/EditWorkingHoursModal'
import EditAlertModal from './components/EditAlertModal'
import CookieSettingsModal from './components/CookieSettingsModal'
import { useModal } from '../../components/ModalWindow'
import { Address, Phone, WorkingHours, AlertMessage, StoreSettings, CookieSettings } from './types'
import { useSettings } from '../../features/common/commonHooks'
import { useAppDispatch, useAsyncRequestDispatch } from '../../store'
import { setSettings as setSettingsAction } from '../../features/common/commonSlice'

type PropTypes = {}

const Settings = ({}: PropTypes) => {
    const asyncDispatch = useAsyncRequestDispatch()
    const dispatch = useAppDispatch()
    const [settings, setSettings] = useState<StoreSettings>({})
    const { settings: currentSettings } = useSettings()
    useEffect(() => {
        setSettings(currentSettings)
    }, [currentSettings])

    const { open: addressOpened, closeModal: closeAddressModal, openModal: openAddressModal } = useModal<Address>()
    const { open: phoneOpened, closeModal: closePhoneModal, openModal: openPhoneModal } = useModal<Phone>()
    const { open: hoursOpened, closeModal: closeHoursModal, openModal: openHoursModal } = useModal<WorkingHours>()
    const { open: alertOpened, closeModal: closeAlertModal, openModal: openAlertModal } = useModal<AlertMessage>()
    const { open: cookieOpened, closeModal: closeCookieModal, openModal: openCookieModal } = useModal<CookieSettings>()

    const handleSave = (field: string, value: Phone | Address | WorkingHours | AlertMessage) => {
        const newSettings = { ...settings, [field]: value }
        switch (field) {
            case 'address':
                closeAddressModal()
                break
            case 'phone':
                closePhoneModal()
                break
            case 'hours':
                closeHoursModal()
                break
            case 'alert':
                closeAlertModal()
                break
            case 'cookie':
                closeCookieModal()
                break
        }

        setSettings(newSettings)
        asyncDispatch(setSettingsAction({ settings: newSettings }), 'Changes saved').then()
    }

    const handleToggleEnabled = (enabled: boolean) => {
        const newSettings = { ...settings, alert: { ...settings.alert, enabled } }
        setSettings(newSettings)
        dispatch(setSettingsAction({ settings: newSettings }))
    }

    return (
        <Grid container spacing={2}>
            {settings?.address && (
                <SettingsContainer title="Office Address" onEdit={() => openAddressModal(settings.address as Address)}>
                    <>
                        <OfficeAddressBlock address={settings.address as Address} />
                        <EditAddressModal
                            open={addressOpened}
                            onClose={closeAddressModal}
                            onSave={(val) => handleSave('address', val)}
                            settings={settings.address as Address}
                        />
                    </>
                </SettingsContainer>
            )}
            {settings?.phone && (
                <SettingsContainer
                    title="Phone"
                    onEdit={() => openPhoneModal(settings.phone as Phone)}
                    sx={{ minHeight: '152px' }}
                >
                    <>
                        <PhoneBlock phone={settings.phone as Phone} />
                        <EditPhoneModal
                            open={phoneOpened}
                            onClose={closePhoneModal}
                            onSave={(val) => handleSave('phone', val)}
                            settings={settings.phone as Phone}
                        />
                    </>
                </SettingsContainer>
            )}
            {settings?.hours && (
                <SettingsContainer
                    title="Office Hours"
                    onEdit={() => openHoursModal(settings.hours as WorkingHours)}
                    sx={{ minHeight: '260px' }}
                >
                    <>
                        <WorkingHoursBlock hours={settings.hours as WorkingHours} />
                        <EditWorkingHoursModal
                            open={hoursOpened}
                            onClose={closeHoursModal}
                            onSave={(val) => handleSave('hours', val)}
                            settings={settings.hours as WorkingHours}
                        />
                    </>
                </SettingsContainer>
            )}
            {settings?.alert && (
                <SettingsContainer
                    title="Alert Message"
                    onEdit={() => {
                        openAlertModal(settings.alert as AlertMessage)
                    }}
                    sx={{ minHeight: '260px' }}
                >
                    <>
                        <AlertMessageBlock
                            data={settings.alert as AlertMessage}
                            onToggleAlertMessage={handleToggleEnabled}
                        />
                        <EditAlertModal
                            open={alertOpened}
                            onClose={closeAlertModal}
                            onSave={(val) => handleSave('alert', val)}
                            settings={settings.alert as AlertMessage}
                        />
                    </>
                </SettingsContainer>
            )}
            {settings?.cookie && (
                <SettingsContainer
                    title="Cookies Settings"
                    onEdit={() => {
                        openCookieModal(settings.cookie as CookieSettings)
                    }}
                    sx={{ minHeight: '260px' }}
                >
                    <>
                        <CookieSettingBlock data={settings.cookie as CookieSettings} />
                        <CookieSettingsModal
                            open={cookieOpened}
                            onClose={closeCookieModal}
                            onSave={(val) => handleSave('cookie', val)}
                            settings={settings.cookie as CookieSettings}
                        />
                    </>
                </SettingsContainer>
            )}
        </Grid>
    )
}

export default Settings

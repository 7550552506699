import * as React from 'react'
import { Box, List, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useCategories } from '../../../features/cms/cmsHooks'
import CategoryItem from './components/CategoryItem'
import { Categories } from '../../../features/cms/cmsSlice'

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

const CmsCategories = () => {
    const navigate = useNavigate()
    const { categories } = useCategories()

    const handleClick = (category: Categories) => {
        navigate('/cms/pages', { state: { categoryId: category.id } })
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap, my: 4 }}>
                <Typography component="h1" variant="h5">
                    Categories
                </Typography>
            </Box>
            <List sx={{ width: '100%' }}>
                {categories.map((v) => {
                    return <CategoryItem key={v.id} category={v} onItemClick={handleClick} />
                })}
            </List>
        </>
    )
}

export default CmsCategories

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Button, List, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector, useAsyncRequestDispatch } from '../../../store'
import {
    fetchResourceCategories,
    fetchResources,
    removeCategory,
    removeResource,
    ResourceCategory,
} from '../../../features/resources/resourcerSlice'
import RowItem, { Resource } from './components/RowItem'
import CategoryItem from '../categories/components/CategoryItem'
import CreateOrUpdateArticle from './components/CreateOrUpdateArticle'
import { useStateCallback } from '../../../common/hooks'
import DeleteConfirmModal, { useDeleteControllers } from '../../../components/DeleteConfirmModal'
import CreateOrUpdateCategoryModal, { useEditCategory } from '../categories/components/CreateOrUpdateCategoryModal'
import { useResourceCategories } from '../../../features/resources/resourcesHooks'

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

export interface ResourceItem {
    id: string
}

const ResourceItems = () => {
    const categoryId = useLocation()?.state?.category?.id
    const { categories } = useResourceCategories()
    const { displayEdit, openEditCategory, setEditedCategory, editedCategory, onCloseEdit } = useEditCategory({})
    useEffect(() => {
        setEditedCategory(categories.find((i) => i.id === categoryId) || {})
    }, [categories])

    const { openedDeleteConfirm, openDeleteModal, closeConfirm, confirmDelete } = useDeleteControllers()
    const {
        openedDeleteConfirm: openedDeleteConfirmRes,
        openDeleteModal: openDeleteModalRes,
        closeConfirm: closeConfirmRes,
        confirmDelete: confirmDeleteRes,
    } = useDeleteControllers()

    const [openEditModal, setOpenEditModal] = useState(false)
    const [editResource, setEditResource] = useStateCallback<Resource>({})
    const resources = useAppSelector((state) => state.resources.items)
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()
    const navigate = useNavigate()

    const { totalCount, items = [] } = resources?.[categoryId] ?? {}

    // Fetch category page data
    useEffect(() => {
        if (totalCount === undefined) {
            dispatch(fetchResources({ categoryId, page: 0, pageSize: 50 }))
        }
    }, [categoryId, totalCount])

    const buildArticle = () => {
        return { categoryId }
    }

    const handleClickItem = (row: Resource) => {
        navigate(`/resource/edit/${row.id}`)
    }

    const onDeleteItemConfirm = async (id: string) => {
        await asyncDispatch(removeResource({ id }), 'Article deleted')
        await dispatch(fetchResources({ categoryId, page: 0, pageSize: 50 }))
        dispatch(fetchResourceCategories())
    }

    const handleOpenEditModal = (isOpen: boolean, resource: Resource = {}) => {
        setEditResource(resource, () => {
            setOpenEditModal(isOpen)
        })
    }

    const onDeleteConfirm = async (categoryId: string) => {
        await asyncDispatch(removeCategory({ categoryId }), 'Category deleted')
        await dispatch(fetchResourceCategories())
        navigate(-1)
    }

    if (!editedCategory) {
        return null
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap, mt: 3 }}>
                <Typography variant="subtitle2" onClick={() => navigate(-1)} className="breadcrumbs">
                    Back to Categories
                </Typography>

                <Box sx={{ mt: 1, mb: 4 }}>
                    <Button size="large" variant="outlined" onClick={() => openDeleteModal(categoryId)}>
                        Delete Category
                    </Button>
                </Box>
            </Box>
            <List sx={{ width: '100%' }}>
                <CategoryItem
                    category={editedCategory as ResourceCategory}
                    onItemClick={() => {}}
                    onEdit={() => openEditCategory()}
                    freezeControls={true}
                    showControls={true}
                    size="large"
                />
            </List>
            <Box sx={{ ...st.boxWrap, mt: 3 }}>
                <Typography variant="h6">Articles</Typography>
                <Box sx={{ mt: 1, mb: 3 }}>
                    <Button size="large" variant="contained" onClick={() => handleOpenEditModal(true, buildArticle())}>
                        Create New Article
                    </Button>
                </Box>
            </Box>
            <List sx={{ width: '100%' }}>
                {items.map((v, k) => {
                    return (
                        <RowItem
                            row={v}
                            index={k + 1}
                            key={v.id}
                            onClick={handleClickItem}
                            onEdit={() => handleOpenEditModal(true, v)}
                            onDelete={() => openDeleteModalRes(v.id)}
                        />
                    )
                })}
            </List>
            <CreateOrUpdateCategoryModal open={displayEdit} onClose={onCloseEdit} category={editedCategory} />
            <CreateOrUpdateArticle
                open={openEditModal}
                resource={editResource}
                onClose={() => handleOpenEditModal(false)}
            />
            <DeleteConfirmModal
                open={openedDeleteConfirm}
                onConfirm={() => confirmDelete(onDeleteConfirm)}
                onCancel={closeConfirm}
            />
            <DeleteConfirmModal
                open={openedDeleteConfirmRes}
                title="Delete Article"
                message="Are you sure want to delete article"
                okButtonText="Delete Article"
                onConfirm={() => confirmDeleteRes(onDeleteItemConfirm)}
                onCancel={closeConfirmRes}
            />
        </>
    )
}

export default ResourceItems

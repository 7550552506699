import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    KeyboardDoubleArrowLeft,
    KeyboardDoubleArrowRight,
} from '@mui/icons-material'
import _ from 'lodash'
import CustomList from './components/CustomList'
import MoveButton from './components/MoveButton'

enum ListType {
    all = 'all',
    picked = 'picked',
}

export type ItemType = {
    id: string
    title: string
}

export const useTransferList = (selectedItems: ItemType[] = []) => {
    const [selected, setSelected] = useState<ItemType[]>(selectedItems)

    const onChangeSelected = (newItems: ItemType[]) => {
        setSelected(newItems)
    }

    return { selected, onChangeSelected }
}

type PropTypes = {
    items: ItemType[]
    selected: ItemType[]
    onChangeSelected: (i: ItemType[]) => void
    leftTitle?: string
    rightTitle?: string
    title?: string
    maxItemsSelected?: number
    colorizeItems?: number
}

const TransferList = ({
    items,
    selected,
    onChangeSelected,
    leftTitle = '',
    rightTitle = '',
    title = '',
    maxItemsSelected = 0,
    colorizeItems = 0,
}: PropTypes) => {
    const [left, setLeft] = useState<ItemType[]>(items)
    const [right, setRight] = useState<ItemType[]>(selected)
    const [leftChecked, setLeftChecked] = useState<string[]>([])
    const [rightChecked, setRightChecked] = useState<string[]>([])

    useEffect(() => {
        const selectedIds = selected.map((i) => i.id)
        const itemsIds = items.map((i) => i.id)
        setLeft(items.filter((i) => !selectedIds.includes(i.id)))
        setRight(selected.filter((i) => itemsIds.includes(i.id)))
    }, [items, selected])

    const handleToggle = (type: string) => (value: ItemType) => () => {
        const [toggleItems, setToggleItems] =
            type === ListType.all ? [leftChecked, setLeftChecked] : [rightChecked, setRightChecked]
        setToggleItems(_.xor(toggleItems, [value.id]))
    }

    const handleCheckedRight = () => {
        setLeftChecked([])
        const newSelected = items.filter((i) => leftChecked.includes(i.id))
        const oldSelected = selected.filter((i) => !leftChecked.includes(i.id))
        onChangeSelected([...newSelected, ...oldSelected])
    }

    const handleCheckedLeft = () => {
        setRightChecked([])
        onChangeSelected(selected.filter((i) => !rightChecked.includes(i.id)))
    }

    const handleMoveSelected = (id: string, direction: number) => {
        const index = selected.findIndex((i) => i.id === id)
        // Can't be moved before or after (last|first)
        if (0 > index + direction || index + direction >= selected.length) {
            return false
        }

        const movedItem = selected[index]
        let newOrder = [...selected]
        newOrder.splice(index, 1, selected[index + direction])
        newOrder.splice(index + direction, 1, movedItem)
        onChangeSelected(newOrder)
    }

    const handleAllLeft = () => {
        onChangeSelected([])
    }

    const handleAllRight = () => {
        onChangeSelected(items)
    }

    return (
        <>
            {!!title && (
                <Typography variant="h5" sx={{ mb: 3 }}>
                    {title}
                </Typography>
            )}
            <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <Box flex="1">
                    <CustomList
                        items={left}
                        handleToggle={handleToggle(ListType.all)}
                        checked={leftChecked}
                        title={leftTitle}
                    />
                </Box>
                <Box sx={{ width: '56px', ml: 4, mr: 4 }}>
                    <Grid
                        container
                        direction="column"
                        alignItems="center"
                        sx={{ justifyContent: 'center', height: '100%' }}
                    >
                        <MoveButton onClick={handleAllLeft} disabled={selected.length === 0}>
                            <KeyboardDoubleArrowLeft />
                        </MoveButton>
                        <MoveButton onClick={handleCheckedLeft} disabled={rightChecked.length === 0}>
                            <KeyboardArrowLeft />
                        </MoveButton>
                        <MoveButton
                            onClick={handleCheckedRight}
                            disabled={
                                leftChecked.length === 0 ||
                                (!!maxItemsSelected && leftChecked.length + selected.length > maxItemsSelected)
                            }
                        >
                            <KeyboardArrowRight />
                        </MoveButton>
                        <MoveButton
                            onClick={handleAllRight}
                            disabled={
                                !!maxItemsSelected &&
                                (items.length === selected.length || items.length > maxItemsSelected)
                            }
                        >
                            <KeyboardDoubleArrowRight />
                        </MoveButton>
                    </Grid>
                </Box>
                <Box flex="1">
                    <CustomList
                        items={right}
                        handleToggle={handleToggle(ListType.picked)}
                        checked={rightChecked}
                        title={rightTitle}
                        ordered={true}
                        onMove={handleMoveSelected}
                        subtitle={!!maxItemsSelected ? `(Max. items allowed: ${maxItemsSelected} )` : ''}
                        colorizeItems={colorizeItems}
                    />
                </Box>
            </Box>
        </>
    )
}

export default TransferList

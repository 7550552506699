import React, { useState } from 'react'
import { InputAdornment, TextField, TextFieldProps } from '@mui/material'
import { RemoveRedEyeOutlined, VisibilityOffOutlined } from '@mui/icons-material'

const st = {
    eyeButton: { color: '#C8C8CD', cursor: 'pointer' },
}

const PasswordInput = (props: TextFieldProps) => {
    const [toggleType, setToggleType] = useState<string>('password')
    return (
        <TextField
            {...props}
            type={toggleType}
            InputProps={{
                endAdornment: (
                    <InputAdornment
                        position="end"
                        onClick={() => setToggleType(toggleType === 'password' ? 'text' : 'password')}
                    >
                        {toggleType === 'password' ? (
                            <RemoveRedEyeOutlined sx={st.eyeButton} />
                        ) : (
                            <VisibilityOffOutlined sx={st.eyeButton} />
                        )}
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default PasswordInput

import React, { useRef, useState } from 'react'
import { Box, TextField } from '@mui/material'
import SwitchButton from './SwitchButton'
import ModalWindow, { ButtonsBlock } from '../../../components/ModalWindow'
import { getFormData } from '../../../common/helpers'
import { CookieSettings } from '../types'
import TextEditor from '../../../components/TextEditor'

type PropTypes = {
    open: boolean
    onClose: () => void
    onSave: (a: any) => void
    settings: CookieSettings
}

const CookieSettingsModal = ({ open, onClose, settings, onSave }: PropTypes) => {
    const editorRef = useRef(null)
    const [data, setData] = useState({
        acceptButtonEnable: settings.acceptButtonEnable === 'on',
        declineButtonEnable: settings.declineButtonEnable === 'on',
    })

    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        const formData = getFormData(e)
        const current = editorRef?.current
        if (!current) {
            return false
        }

        onSave({
            ...formData,
            acceptButtonEnable: data.acceptButtonEnable ? 'on' : 'off',
            declineButtonEnable: data.declineButtonEnable ? 'on' : 'off',
            // @ts-ignore
            message: current.getContent(),
        })
        onClose()
    }

    return (
        <ModalWindow open={open} title="Edit Cookies Banner" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    size="small"
                    required
                    fullWidth
                    label="Title"
                    name="title"
                    defaultValue={settings.title}
                    autoFocus
                    sx={{ mb: 1 }}
                />
                <Box sx={{ mb: 1 }}>
                    <TextField
                        margin="normal"
                        size="small"
                        required
                        label="Accept Button Title"
                        name="acceptButtonText"
                        defaultValue={settings.acceptButtonText}
                        autoFocus
                    />
                    <div style={{ display: 'inline-block', padding: '14px 0 0 20px' }}>
                        <SwitchButton
                            onText="Enabled"
                            offText="Disabled"
                            onCheck={() => {
                                setData({ ...data, acceptButtonEnable: !data.acceptButtonEnable })
                            }}
                            checked={data.acceptButtonEnable}
                        />
                    </div>
                </Box>
                <Box sx={{ mb: 1 }}>
                    <TextField
                        margin="normal"
                        size="small"
                        required
                        label="Decline Button Title"
                        name="declineButtonText"
                        defaultValue={settings.declineButtonText}
                        autoFocus
                    />
                    <div style={{ display: 'inline-block', padding: '14px 0 0 20px' }}>
                        <SwitchButton
                            onText="Enabled"
                            offText="Disabled"
                            onCheck={() => {
                                setData({ ...data, declineButtonEnable: !data.declineButtonEnable })
                            }}
                            checked={data.declineButtonEnable}
                        />
                    </div>
                </Box>
                <TextEditor ref={editorRef} content={settings.message} tools="link" height={200} />
                <ButtonsBlock onCancel={onClose} />
            </Box>
        </ModalWindow>
    )
}

export default CookieSettingsModal

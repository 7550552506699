import React from 'react'
import { Typography } from '@mui/material'
import { TitleItem } from '../../routes'

type PropTypes = { item: TitleItem }

const Title = ({ item }: PropTypes) => {
    if (typeof item === 'string') {
        return (
            <Typography className="last" component="h1" variant="body2" noWrap>
                {item}
            </Typography>
        )
    }

    return (
        <>
            <Typography className="title" component="h6" variant="body2" noWrap>
                {Object.keys(item)?.[0]}
            </Typography>
            <Title item={Object.values(item)?.[0]} />
        </>
    )
}

export default Title

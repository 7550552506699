import React, { useState } from 'react'
import z from 'zod'
import _ from 'lodash'
import { Box, TextField } from '@mui/material'
import ModalWindow, { ButtonsBlock } from '../../../components/ModalWindow'
import { getFormData, validate } from '../../../common/helpers'
import { WorkingHours, DAYS } from '../types'

const schema = z.object({
    monday: z.string().min(3),
    tuesday: z.string().min(3),
    wednesday: z.string().min(3),
    thursday: z.string().min(3),
    friday: z.string().min(3),
    saturday: z.string().min(3),
    sunday: z.string().min(3),
})

const defaultProps = (name: string) => ({
    name,
    label: _.capitalize(name),
    margin: 'normal' as 'normal',
    required: true,
    fullWidth: true,
    autoFocus: true,
})

type PropTypes = {
    open: boolean
    onClose: () => void
    onSave: (a: any) => void
    settings: WorkingHours
}

const EditWorkingHoursModal = ({ open, onClose, settings, onSave }: PropTypes) => {
    const [errorFields, setErrorFields] = useState<string[]>([])
    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        const data = getFormData(e)
        const { errors, result } = validate(data, schema)
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }
        onSave(result)
        onClose()
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <ModalWindow open={open} title="Edit Office Hours" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <>
                    {DAYS.map((i) => (
                        <TextField key={i} {...defaultProps(i)} defaultValue={settings[i]} error={hasError(i)} />
                    ))}
                    <ButtonsBlock onCancel={onClose} />
                </>
            </Box>
        </ModalWindow>
    )
}

export default EditWorkingHoursModal

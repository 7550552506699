import { hhm } from '../../api/hhm'
import { Page } from './cmsSlice'

const CMS_API = {
  getCategories: () => hhm.get('cms/categories', { params: { withPagesCount: true } }),
  getPages: () => hhm.get('cms/pages'),
  updatePage: (id: string, data: Page) => hhm.patch(`cms/pages/${id}`, data),
}

export default CMS_API

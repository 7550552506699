export enum BlockType {
  html = 'html',
  image = 'image',
  video = 'video',
}

export type BlockItem = { type: BlockType | null; id: string; content?: string }

export enum AddPosition {
  before = -1,
  after = 1,
}

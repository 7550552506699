import React, { useState } from 'react'
import { Box, Card, Typography } from '@mui/material'

const st = {
    iconContainer: {
        p: '20px',
        border: '1px solid #E6E6E9',
        borderRadius: '4px',
        display: 'inline-block',
        height: '72px',
        width: '72px',
        mr: 3,
    },
}

type PropTypes = {
    icon: React.ReactNode
    title: string
    value: number
}

const CardItem = ({ icon, value, title }: PropTypes) => {
    return (
        <Card sx={{ p: 2 }}>
            <Box sx={st.iconContainer}>{icon}</Box>
            <Box sx={{ width: '100px', display: 'inline-block', verticalAlign: 'top' }}>
                <Typography variant="body2" sx={{ mb: 0.5, color: '#9F9FA8' }}>
                    {title}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: 700, fontSize: '36px' }}>
                    {!!Number(value) && Number(value).toLocaleString()}
                </Typography>
            </Box>
        </Card>
    )
}

export default CardItem

import React from 'react'
import { Card, Grid, IconButton, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'

const st = {
    buttonStyle: { width: '48px', height: '48px', position: 'absolute', top: '24px', right: '24px' },
}

type PropTypes = { title: string; children?: React.ReactElement; onEdit?: () => void; sx?: object }

const SettingsContainer = ({ children, title, onEdit, sx = {} }: PropTypes) => {
    return (
        <Grid item sm={12} md={6}>
            <Card sx={{ p: 3, position: 'relative', minHeight: '96px', ...sx }}>
                <Typography variant="h5" sx={{ mb: 3 }}>
                    {title}
                </Typography>
                <div style={{ color: '#64646C' }}>{children}</div>
                <IconButton sx={st.buttonStyle} onClick={onEdit}>
                    <Edit />
                </IconButton>
            </Card>
        </Grid>
    )
}

export default SettingsContainer

import React, { useState } from 'react'
import z from 'zod'
import { Box, TextField } from '@mui/material'
import ModalWindow, { ButtonsBlock } from '../../../components/ModalWindow'
import { getFormData, validate } from '../../../common/helpers'
import { Address } from '../types'

const schema = z.object({
    address1: z.string().min(3),
    address2: z.string().min(3),
})

type PropTypes = {
    open: boolean
    onClose: () => void
    onSave: (a: any) => void
    settings: Address
}

const EditAddressModal = ({ open, onClose, settings, onSave }: PropTypes) => {
    const [errorFields, setErrorFields] = useState<string[]>([])
    const handleSave = (e: React.FormEvent<HTMLFormElement>) => {
        const data = getFormData(e)
        const { errors, result } = validate(data, schema)
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }
        onSave(result)
        onClose()
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <ModalWindow open={open} title="Edit Office Address" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Address 1"
                    name="address1"
                    defaultValue={settings.address1}
                    autoFocus
                    error={hasError('address1')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    name="address2"
                    defaultValue={settings.address2}
                    label="Address 2"
                    error={hasError('address2')}
                />
                <ButtonsBlock onCancel={onClose} />
            </Box>
        </ModalWindow>
    )
}

export default EditAddressModal

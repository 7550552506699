import { createTheme } from '@mui/material/styles'
import createPalette from '@mui/material/styles/createPalette'

const palette = createPalette({
  primary: {
    main: '#222944',
  },
  background: {
    default: '#F5F5F6',
  },
})
const theme = createTheme({
  palette,
  typography: {
    button: {
      textTransform: 'none',
    },
    subtitle2: {
      '&.breadcrumbs': {
        cursor: 'pointer !important',
        ':before': {
          content: '"❮"',
          display: 'inline',
          padding: '0 8px 0 0',
        },
      },
    },
  },
})

export default theme

import { hhm } from '../../api/hhm'
import { DEFAULT_PAGE_SIZE } from '../../constants'
import { Resource } from '../../pages/resources/editResource/EditResource'
import { Category } from '../../pages/resources/categories/components/CategoryItem'

export interface FeaturedResource {
  id: string
}

export interface Tag {
  id?: string
  title?: string
  description?: string
}

const RESOURCE_API = {
  getCategories: () => hhm.get('resources/categories', { params: { withResourceCount: true } }),

  addOrUpdateCategory: (category: Category) => {
    if (category.id) {
      return hhm.patch(`resources/categories/${category.id}`, category)
    } else {
      return hhm.post(`resources/categories`, category)
    }
  },

  getResourcesByCategory: (categoryId: string, page = 1, pageSize = DEFAULT_PAGE_SIZE) =>
    hhm.post('resources/all', { filters: { categoryId }, page: page + 1, pageSize }),

  getAllResources: () => hhm.post('resources/all', { page: 1, pageSize: 50 }),

  getAvailableTags: () => hhm.get(`resources/tags`),

  addTag: (title: string) => hhm.post(`resources/tags`, { title }),

  updateTag: (tagId: string, data: Tag) => hhm.put(`resources/tags/${tagId}`, data),

  removeTag: (tagId: string) => hhm.delete(`resources/tags/${tagId}`),

  getResourceById: (resourceId: string) => hhm.get(`resources/${resourceId}`),

  addOrUpdateResource: (resource: Resource) => {
    const tagIds = (resource?.tags ?? []).map((i) => i.id)
    if (resource.id) {
      return hhm.patch(`resources/${resource.id}`, { ...resource, tagIds })
    } else {
      return hhm.post(`resources`, { ...resource, tagIds, content: { blocks: [] } })
    }
  },

  removeCategory: (categoryId: string) => hhm.delete(`resources/categories/${categoryId}`),

  removeResource: (resourceId: string) => hhm.delete(`resources/${resourceId}`),

  getFeaturedCategories: () => hhm.get(`resources/categories/picked`),

  getFeaturedResources: () => hhm.get(`resources/picked`),

  setFeaturedCategories: (categories: Category[]) => {
    return hhm.put(`resources/categories/picked`, {
      items: categories.map(({ id }, k) => ({ categoryId: id, orderNumber: k })),
    })
  },

  setFeaturedItems: (resource: FeaturedResource[]) => {
    return hhm.put(`resources/picked`, {
      items: resource.map(({ id }, k) => ({ resourceId: id, orderNumber: k })),
    })
  },
}

export default RESOURCE_API

import React, { useState } from 'react'
import z from 'zod'
import { Box, Button, TextField } from '@mui/material'
import ModalWindow, { st } from '../../../../components/ModalWindow'
import { getFormData, validate } from '../../../../common/helpers'
import { useAsyncRequestDispatch } from '../../../../store'
import { updatePersonal } from '../../../../features/auth/authSlice'

export const useEditPersonal = () => {
    const [opened, setOpened] = useState(false)

    const onClose = () => {
        setOpened(false)
    }

    const openModal = () => {
        setOpened(true)
    }

    return { opened, onClose, openModal }
}

const schema = z.object({
    name: z.string().min(2),
    surname: z.string().min(2),
})

type PropTypes = {
    open: boolean
    onClose: () => void
    user: { name: string; surname: string }
}

const EditPersonalModal = ({ open, onClose, user }: PropTypes) => {
    const asyncDispatch = useAsyncRequestDispatch()
    const [errorFields, setErrorFields] = useState<string[]>([])

    const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
        setErrorFields([])
        const data = getFormData(e)
        const { result, errors } = validate(data, schema)
        if (errors) {
            setErrorFields(errors.map((i) => i.field))
            return
        }

        await asyncDispatch(updatePersonal(result), 'Your profile updated')
        onClose()
    }

    const hasError = (field: string) => errorFields.includes(field)

    return (
        <ModalWindow open={open} title="Edit Personal Info" width="490px" onClose={() => onClose()}>
            <Box component="form" onSubmit={handleSave} noValidate sx={{ mt: 2, width: '100%' }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="First Name"
                    name="name"
                    defaultValue={user.name}
                    autoFocus
                    error={hasError('name')}
                    sx={{ mb: 2 }}
                />
                <TextField
                    required
                    fullWidth
                    margin="normal"
                    name="surname"
                    defaultValue={user.surname}
                    label="Last Name"
                    error={hasError('surname')}
                />
                <Box sx={st.blockButtons}>
                    <Button variant="text" size="large" sx={{ mr: 1 }} onClick={() => onClose()}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="contained" size="large">
                        Save changes
                    </Button>
                </Box>
            </Box>
        </ModalWindow>
    )
}

export default EditPersonalModal

import * as _ from 'lodash'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import RESOURCE_API, { FeaturedResource, Tag } from './resourcesApi'
import { Resource, TagItem } from '../../pages/resources/editResource/EditResource'
import { BlockItem } from '../../components/EditContent/types'
import { Category } from '../../pages/resources/categories/components/CategoryItem'
import { resetAll } from '../common/commonSlice'

export interface ResourceCategory {
  id: string
  title: string
  description: string
  icon?: string
  resourcesCount?: number
}

export interface ResourceItem {
  id: string
  title: string
  content: { blocks?: BlockItem[][] }
  categoryId: string
  tags: [{ id: string; title: string }]
  description?: string
  keywords?: string
  url: string
  fullUrl: string
}

export interface ResourceCategoryState {
  loading: boolean
  categories: ResourceCategory[]
  items: { [key: string]: { totalCount: number; items: ResourceItem[] } }
  tags: TagItem[]
  error: string | undefined
  featuredCategories: any[]
  featuredItems: any[]
}

const initialState: ResourceCategoryState = {
  loading: false,
  categories: [],
  items: {},
  tags: [],
  featuredCategories: [],
  featuredItems: [],
  error: undefined,
}

export const fetchResourceCategories = createAsyncThunk('resources/fetchCategories', async () => {
  const { data } = await RESOURCE_API.getCategories()
  return data
})

export const fetchResources = createAsyncThunk(
  'resources/fetchItems',
  async (params: { categoryId: string; page: number; pageSize: number }) => {
    const { categoryId, page, pageSize } = params
    const {
      data: { totalCount, items },
    } = await RESOURCE_API.getResourcesByCategory(categoryId, page, pageSize)

    return { totalCount, items, categoryId, page }
  },
)

export const fetchAllResources = createAsyncThunk('resources/fetchAll', async (): Promise<ResourceItem[]> => {
  const {
    data: { items },
  } = await RESOURCE_API.getAllResources()
  return items
})

export const fetchTags = createAsyncThunk('resources/fetchTags', async () => {
  const { data } = await RESOURCE_API.getAvailableTags()

  return data
})

export const addTag = createAsyncThunk('resources/addTag', async (params: { title: string }) => {
  const { data } = await RESOURCE_API.addTag(params.title)

  return data
})

export const updateTag = createAsyncThunk('resources/updateTag', async (params: { id: string; data: Tag }) => {
  const { data } = await RESOURCE_API.updateTag(params.id, params.data)

  return data
})

export const removeTag = createAsyncThunk('resources/removeTag', async (params: { id: string }) => {
  await RESOURCE_API.removeTag(params.id)
  return { id: params.id }
})

export const addOrUpdateResource = createAsyncThunk(
  'resources/addOrUpdateResource',
  async (params: { resource: Resource }) => {
    const { resource } = params
    const { data } = await RESOURCE_API.addOrUpdateResource(resource)
    return data
  },
)

export const addOrUpdateCategory = createAsyncThunk(
  'resources/addOrUpdateCategory',
  async (params: { category: Category }) => {
    const { data } = await RESOURCE_API.addOrUpdateCategory(params.category)
    return data
  },
)

export const removeCategory = createAsyncThunk('resources/removeCategory', async (params: { categoryId: string }) => {
  const { data } = await RESOURCE_API.removeCategory(params.categoryId)
  return data
})

export const removeResource = createAsyncThunk('resources/removeResource', async (params: { id: string }) => {
  const { data } = await RESOURCE_API.removeResource(params.id)
  return data
})

export const fetchFeatured = createAsyncThunk('resources/fetchFeatured', async () => {
  const [{ data: featuredCategories }, { data: featuredItems }] = await Promise.all([
    RESOURCE_API.getFeaturedCategories(),
    RESOURCE_API.getFeaturedResources(),
  ])

  return { featuredCategories, featuredItems }
})

export const setFeatured = createAsyncThunk(
  'resources/setFeatured',
  async (params: { featuredCategories: Category[]; featuredItems: FeaturedResource[] }) => {
    const { featuredCategories, featuredItems } = params
    await Promise.all([
      RESOURCE_API.setFeaturedCategories(featuredCategories),
      RESOURCE_API.setFeaturedItems(featuredItems),
    ])
  },
)

type FetchItemsResponse = {
  totalCount: number
  items: ResourceItem[]
  categoryId: string
  page: number
}

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  // Fetch categories
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState)

    builder.addCase(fetchResourceCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchResourceCategories.fulfilled, (state, action: PayloadAction<ResourceCategory[]>) => {
      state.loading = false
      state.categories = action.payload
    })
    builder.addCase(fetchResourceCategories.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // Fetch resources page
    builder.addCase(fetchResources.fulfilled, (state, action: PayloadAction<FetchItemsResponse>) => {
      const { totalCount, items, categoryId } = action.payload
      state.loading = false
      // const stateItemsByCategory = state.items?.[categoryId] ?? {}
      state.items = { ...state.items, [categoryId]: { totalCount, items } }
    })

    //Fetch tags
    builder.addCase(fetchTags.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchTags.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(fetchTags.fulfilled, (state, action: PayloadAction<TagItem[]>) => {
      state.tags = action.payload
    })

    // Update resource
    builder.addCase(addOrUpdateResource.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addOrUpdateResource.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(addOrUpdateResource.fulfilled, (state) => {
      state.loading = false
      state.items = {}
    })

    // Update resource
    builder.addCase(addOrUpdateCategory.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addOrUpdateCategory.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })
    builder.addCase(addOrUpdateCategory.fulfilled, (state) => {
      state.loading = false
      state.categories = []
    })

    // Fetch featured
    builder.addCase(
      fetchFeatured.fulfilled,
      (state, action: PayloadAction<{ featuredCategories: any[]; featuredItems: any[] }>) => {
        const { featuredCategories, featuredItems } = action.payload
        state.featuredItems = featuredItems
        state.featuredCategories = featuredCategories
      },
    )

    builder.addCase(addTag.fulfilled, (state, action: PayloadAction<TagItem>) => {
      state.tags.unshift(action.payload)
    })

    builder.addCase(updateTag.fulfilled, (state, action: PayloadAction<TagItem>) => {
      const index = state.tags.findIndex((i) => i.id === action.payload.id)
      if (index !== -1) {
        state.tags[index] = { ...state.tags[index], ...action.payload }
      }
    })

    builder.addCase(removeTag.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
      state.tags = state.tags.filter((i) => i.id !== action.payload.id)
    })
  },
  reducers: {
    removeCategoryData: (state, action: PayloadAction<{ categoryId: string }>) => {
      state.items = _.omit(state.items, [action.payload.categoryId])
    },
  },
})

export default resourcesSlice.reducer

import React from 'react'
import { Box, Typography, Paper, Button } from '@mui/material'
import ChangePasswordModal, { useChangePassword } from './components/ChangePasswordModal'
import EditPersonalModal, { useEditPersonal } from './components/EditPersonalModal'
import { useCurrentUser } from '../../../common/hooks'
import { prettifyUserRoles } from '../../../common/helpers'

type PropTypes = {}

const Profile = ({}: PropTypes) => {
    const { opened, onClose, openModal } = useChangePassword()
    const { user } = useCurrentUser()
    const { opened: openedPersonal, onClose: onClosePersonal, openModal: openModalPersonal } = useEditPersonal()

    if (!user) {
        return null
    }

    return (
        <>
            <Paper sx={{ py: 2, px: 3 }}>
                <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'inline-block' }}>
                        <Typography variant="h5">
                            {user.name} {user.surname}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1, color: '#64646C' }}>
                            {prettifyUserRoles(user.roles)}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'inline-block' }}>
                        <Button variant="outlined" sx={{ mr: 1.5 }} size="large" onClick={openModal}>
                            Change Password
                        </Button>
                        <Button variant="contained" size="large" onClick={openModalPersonal}>
                            Edit Personal Info
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <ChangePasswordModal open={opened} onClose={onClose} />
            <EditPersonalModal open={openedPersonal} onClose={onClosePersonal} user={user} />
        </>
    )
}

export default Profile

import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import { drawerWidth } from './Drawer'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar/AppBar'

interface AppBarProps extends MuiAppBarProps {
    open?: boolean
}
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
    borderBottom: '1px solid #E6E6E9',
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

export default AppBar

import React from 'react'
import { Typography } from '@mui/material'
import { Phone } from '../types'

const PhoneBlock = ({ phone }: { phone: Phone }) => {
    return (
        <Typography variant="body2" sx={{ mb: 3 }}>
            {phone.phone}
        </Typography>
    )
}

export default PhoneBlock

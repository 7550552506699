import React from 'react'
import { Box, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'

type PropTypes = {
    value: string
    onEdit: (id: string) => void
    onDelete: (id: string) => void
}

const RowActions = ({ value, onEdit, onDelete }: PropTypes) => {
    return (
        <Box sx={{ textAlign: 'center', width: '100%' }} flex={1}>
            <IconButton onClick={() => onEdit(value)} sx={{ p: '3px' }}>
                <Edit sx={{ width: '18px', height: '18px' }} />
            </IconButton>
            <IconButton onClick={() => onDelete(value)} sx={{ p: '3px' }}>
                <Delete sx={{ width: '18px', height: '18px' }} />
            </IconButton>
        </Box>
    )
}

export default RowActions

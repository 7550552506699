import { useAppDispatch, useAppSelector } from '../../store'
import { useEffect } from 'react'
import { fetchStats, fetchSettings } from './commonSlice'

export const useStats = () => {
  const dispatch = useAppDispatch()
  const { stats } = useAppSelector((state) => state.common)

  useEffect(() => {
    if (stats.users === 0) {
      dispatch(fetchStats())
    }
  }, [])

  return { stats }
}

export const useSettings = () => {
  const dispatch = useAppDispatch()
  const { settings } = useAppSelector((state) => state.common)

  useEffect(() => {
    if (Object.keys(settings).length === 0) {
      dispatch(fetchSettings())
    }
  }, [])

  return { settings }
}

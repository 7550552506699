import React from 'react'
import { Typography } from '@mui/material'
import { Address } from '../types'

type PropTypes = {
    address: Address
}

const OfficeAddressBlock = ({ address }: PropTypes) => {
    return (
        <>
            <Typography variant="body2" sx={{ mb: 1 }}>
                {address.address1}
            </Typography>
            <Typography variant="body2">{address.address2}</Typography>
        </>
    )
}

export default OfficeAddressBlock

import React, { useState } from 'react'
import { IconButton, ListItem, ListItemAvatar, Typography } from '@mui/material'
import { Delete, Edit, ImageOutlined } from '@mui/icons-material'
import ListItemText from '@mui/material/ListItemText'
import { ResourceCategory } from '../../../../features/resources/resourcerSlice'

export interface Category extends Partial<ResourceCategory> {
    icon?: string
}

const isUrl = (value: any) => (typeof value === 'string' ? !!value.match(/^http(s?):\/\//) : false)

const st = {
    listItem: {
        bgcolor: 'background.paper',
        marginBottom: '12px',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    itemAvatar: {
        width: '56px',
        height: '56px',
        border: '1px solid #E6E6E9',
        borderRadius: '4px',
        margin: '14px 24px 14px 8px',
    },
    itemAvatarLarge: {
        width: '86px',
        height: '86px',
        border: '1px solid #E6E6E9',
        borderRadius: '4px',
        margin: '14px 24px 14px 8px',
    },
    listItemText: {
        '& .MuiListItemText-primary': {
            fontWeight: 'bold',
            marginTop: 0,
        },
    },
    listItemTextLarge: {
        '& .MuiListItemText-primary': {
            fontSize: '24px',
            marginTop: '12px',
            marginBottom: '8px',
        },
    },
    imageThumb: { height: '54px', width: '54px', color: '#F15E75' },
    imageThumbLarge: { height: '84px', width: '84px', color: '#F15E75' },
}

type PropTypes = {
    category: ResourceCategory
    onEdit: (id: string) => void
    onDelete?: (id: string) => void
    onItemClick: (category: Category) => void
    freezeControls?: boolean
    showControls?: boolean
    size?: 'medium' | 'large'
}

const CategoryItem = ({
    category,
    onDelete,
    onEdit,
    onItemClick,
    freezeControls = false,
    showControls = false,
    size = 'medium',
}: PropTypes) => {
    const [hovered, setHovered] = useState(showControls)

    const handleHover = (isHover: boolean) => {
        !freezeControls && setHovered(isHover)
    }

    return (
        <ListItem sx={st.listItem} onMouseEnter={() => handleHover(true)} onMouseLeave={() => handleHover(false)} onClick={() => onItemClick(category)}>
            <div style={{ display: 'flex' }}>
                <ListItemAvatar sx={size === 'large' ? st.itemAvatarLarge : st.itemAvatar}>
                    {isUrl(category?.icon || '') ? (
                        <img src={category.icon} style={size === 'large' ? st.imageThumbLarge : st.imageThumb} />
                    ) : (
                        <ImageOutlined sx={size === 'large' ? st.imageThumbLarge : st.imageThumb} />
                    )}
                </ListItemAvatar>
                <div style={{ display: 'flow', paddingRight: '4px' }}>
                    <ListItemText
                        primary={category.title}
                        secondary={category.description}
                        sx={size === 'large' ? st.listItemTextLarge : st.listItemText}
                    />
                    {size === 'medium' && (
                        <Typography variant="caption" display="block" sx={{ color: '#9F9FA8' }}>
                            { category.resourcesCount } articles
                        </Typography>
                    )}
                </div>
            </div>
            <div style={{ display: 'flex', minWidth: '96px', flexDirection: 'row-reverse' }}>
                <div style={{ display: hovered ? 'block' : 'none' }}>
                    <IconButton onClick={(e) => {
                        e.stopPropagation()
                        onEdit(category.id)
                    }}>
                        <Edit />
                    </IconButton>
                    {typeof onDelete === 'function' && (
                        <IconButton onClick={(e) => {
                            e.stopPropagation()
                            onDelete(category.id)
                        }}>
                            <Delete />
                        </IconButton>
                    )}
                </div>
            </div>
        </ListItem>
    )
}

export default CategoryItem

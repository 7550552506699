import React from 'react'
import sanitizeHtml, { IOptions } from 'sanitize-html'

const defaultOptions: IOptions = {
    allowedTags: [
        'b',
        'i',
        'em',
        'strong',
        'a',
        'p',
        'span',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'img',
        'ol',
        'ul',
        'li',
    ],
    allowedAttributes: {
        a: ['href'],
        p: ['style'],
    },
    allowedIframeHostnames: ['www.youtube.com'],
    parseStyleAttributes: true,
    allowedStyles: {
        p: {
            'text-align': [/^left$/, /^right$/, /^center$/],
        },
    },
}

const sanitize = (dirty: string, options: IOptions = {}, clear = true) => ({
    __html: clear ? sanitizeHtml(dirty, { ...defaultOptions, ...options }) : dirty,
})

type PropTypes = {
    html: string
    options?: object
}

const HtmlBlock = ({ html, options = {} }: PropTypes) => {
    return <div dangerouslySetInnerHTML={sanitize(html, options, false)} />
}

export default HtmlBlock

import React, { useEffect, useRef, useState } from 'react'
import z from 'zod'
import { Button, CircularProgress, Stack } from '@mui/material'
import { UploadFileRounded } from '@mui/icons-material'
import Dropzone from '../../Dropzone'
import { BlockItem, BlockType } from '../types'
import AddVideoForm from './AddVideoForm'
import ErrorsAlert, { ErrorsList } from '../../ErrorsAlert'
import COMMON_API from '../../../features/common/commonApi'
import ModalWindow from '../../ModalWindow'
import TextEditor from '../../TextEditor'

const loadingStyle: React.CSSProperties = {
    position: 'absolute',
    width: 'calc(100% - 64px)',
    textAlign: 'center',
    height: 'calc(100% - 64px)',
    paddingTop: '40px',
    background: 'rgba(255,255,255,0.5)',
    zIndex: 20,
}

type Props = {
    block: BlockItem
    open?: boolean
    onClose?: () => void
    onSave: (value: string) => void
}

const isValidUrl = (url: string) => {
    const { success } = z.string().url().safeParse(url)

    return success ? url : false
}

const EditContentModal = ({ block, open = false, onClose = () => {}, onSave }: Props) => {
    const editorRef = useRef(null)
    const [files, setFiles] = useState<File[]>([])
    const [video, setVideo] = useState<string>()
    const [uploadErrors, setUploadErrors] = useState<ErrorsList>([])
    const [loading, setLoading] = useState(false)
    const { type, content } = block

    useEffect(() => {
        setUploadErrors([])
    }, [open])

    const uploadFiles = async (selectedFiles: File[]): Promise<{ url: string }> => {
        if (!selectedFiles.length) {
            throw new Error('Nothing to upload')
        }
        const result = await Promise.all(
            [selectedFiles[0]].map((file) => {
                return COMMON_API.uploadFile(file)
            }),
        )

        return result?.[0]?.data
    }

    const handleSave = async () => {
        if (type === BlockType.image) {
            if (files) {
                setLoading(true)
                try {
                    const { url } = await uploadFiles(files)
                    setLoading(false)
                    onSave(url)
                } catch (e) {
                    setUploadErrors([{ field: 'Network', message: 'Something goes wrong!' }])
                    setLoading(false)
                }
            }
        } else if (type === BlockType.html) {
            const current = editorRef?.current
            if (current) {
                // @ts-ignore
                onSave(current.getContent())
            }
        } else if (type === BlockType.video) {
            if (isValidUrl(video || '')) {
                onSave(video as string)
            }
        }
    }

    const handleUploadFile = (files: (File & { preview: string })[]) => {
        if (files.length) {
            setFiles(files)
        }
    }

    const handleChangVideoUrl = (url: string) => {
        if (isValidUrl(url)) {
            setVideo(url)
        }
    }

    const width = type === BlockType.video ? '490px' : '1125px'

    return (
        <ModalWindow open={open} onClose={onClose} title={type === BlockType.video ? 'Add Video' : ''} width={width}>
            <>
                <ErrorsAlert errors={uploadErrors} />
                {loading && (
                    <div style={loadingStyle}>
                        <CircularProgress />
                    </div>
                )}
                {type === BlockType.html && <TextEditor content={content} ref={editorRef} />}
                {type === BlockType.image && <Dropzone icon={<UploadFileRounded />} onChange={handleUploadFile} />}
                {type === BlockType.video && <AddVideoForm onChange={handleChangVideoUrl} />}
                <Stack spacing={2} direction="row-reverse" sx={{ mt: 3 }}>
                    <Button variant="contained" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={onClose}>
                        Cancel
                    </Button>
                </Stack>
            </>
        </ModalWindow>
    )
}

export default EditContentModal

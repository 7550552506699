import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import USERS_API from './usersApi'
import { User as Personal } from '../auth/authSlice'
import { resetAll } from '../common/commonSlice'

export interface User extends Omit<Personal, 'id'> {
  id?: string
}

export interface UserState {
  loading: boolean
  users: Array<User>
  error: string | undefined
}

const initialState: UserState = {
  loading: false,
  users: [],
  error: undefined,
}

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const { data } = await USERS_API.getUsers()
  return data
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (params: { id: string }) => {
  const { data } = await USERS_API.deleteUser(params.id)
  return data
})

export const inviteUser = createAsyncThunk('users/inviteUser', async (user: User) => {
  const { data } = await USERS_API.inviteUser(user)
  return data
})

export const updateUser = createAsyncThunk('users/updateUser', async (user: User) => {
  const { data } = await USERS_API.updateUser(user.id as string, user)
  return data
})

const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState)

    builder.addCase(fetchUsers.fulfilled, (state, action: PayloadAction<User[]>) => {
      state.loading = false
      state.users = action.payload
    })

    builder.addCase(deleteUser.fulfilled, (state, action: PayloadAction<User>) => {
      const { id } = action.payload
      state.users = state.users.filter((i) => i.id !== id)
    })
  },
  reducers: {},
})

export default usersSlice.reducer

import React, { useState } from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useStateCallback } from '../common/hooks'

export const st = {
    boxContainer: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        borderRadius: '4px',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 3,
    },
    boxHeader: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
    blockButtons: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right', mt: 3 },
}

export const ButtonsBlock = ({
    onCancel,
    canselTitle = 'Cancel',
    submitTitle = 'Save changes',
}: {
    onCancel: () => void
    canselTitle?: string
    submitTitle?: string
}) => {
    return (
        <Box sx={st.blockButtons}>
            <Button variant="text" size="large" sx={{ mr: 1 }} onClick={onCancel}>
                {canselTitle}
            </Button>
            <Button type="submit" variant="contained" size="large">
                {submitTitle}
            </Button>
        </Box>
    )
}

export const useModal = <T,>() => {
    const [open, setOpen] = useState(false)
    const [entity, setEntity] = useStateCallback<T | null>(null)

    const closeModal = () => {
        setEntity(null, () => {
            setOpen(false)
        })
    }

    const openModal = (address: T) => {
        setEntity(address, () => {
            setOpen(true)
        })
    }

    return { open, closeModal, openModal, entity }
}

type PropTypes = {
    children?: React.ReactNode | null
    onClose: () => void
    open: boolean
    title?: string
    width?: string
}

const ModalWindow = ({ children, title, open = false, width = '490px', onClose }: PropTypes) => {
    const handleClose = () => {}

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...st.boxContainer, width }}>
                {title && (
                    <Box sx={st.boxHeader}>
                        <Typography variant="h6" component="h2">
                            {title}
                        </Typography>
                        <IconButton aria-label="edit" onClick={onClose} size="small">
                            <Close />
                        </IconButton>
                    </Box>
                )}
                {children}
            </Box>
        </Modal>
    )
}

export default ModalWindow

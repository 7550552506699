import React from 'react'
import { ArrowBack } from '@mui/icons-material'
import { Button, TextField, Box, Typography, Container, Link } from '@mui/material'
import AUTH_API from '../../features/auth/authApi'

import { windowStyles as st } from './styles'
import { useAppDispatch } from '../../store'
import { successMessage, errorMessage } from '../../features/common/commonSlice'

export default function ForgotPassword() {
    const dispatch = useAppDispatch()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = new FormData(event.currentTarget)
        AUTH_API.forgotPassword({
            email: data.get('email') as string,
        })
            .then(() => {
                dispatch(successMessage('Reset password request sent. Please check your email.'))
            })
            .catch((e) => {
                dispatch(errorMessage(e.message))
            })
    }

    return (
        <Container component="main" maxWidth="md" sx={{ width: '680px' }}>
            <Box sx={st.mainBox}>
                <Box sx={st.boxWrap}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link href="/#/">
                            <ArrowBack sx={{ mr: 2, mt: 1 }} />
                        </Link>
                        <Typography component="h1" variant="h5">
                            Forgot password
                        </Typography>
                    </Box>
                    <img src="/logo_black.svg" alt="logo" width="100" />
                </Box>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4, width: '100%' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 5, mb: 2 }} size="large">
                        Submit
                    </Button>
                </Box>
            </Box>
        </Container>
    )
}

import axios, { InternalAxiosRequestConfig } from 'axios'

const env = process.env.REACT_APP_ENV || 'local' // process.env.NODE_ENV

const API_URLS = {
  development: 'https://api.dev.hudsonhomesmanagement.com/api',
  stage: 'https://api.stage.hudsonhomesmanagement.com/api',
  local: 'http://localhost:3001',
}

const API_BASE_URL = process.env.REACT_APP_API_ROOT || `https://api.dev.hudsonhomesmanagement.com/api/`

const hhm = axios.create({ baseURL: API_BASE_URL })

const TOKENS_API = {
  getToken: 'auth/login',
  refreshToken: 'auth/refresh',
}

hhm.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('userToken')
    config.headers['Content-Type'] = config?.headers?.['Content-Type'] ?? 'application/json'
    config.headers['Accept'] = config?.headers?.['Accept'] ?? 'application/json'

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  (error) => Promise.reject(error),
)

const interceptorError = (error: any) => {
  const { config, response } = error
  const originalRequest = config
  if (response && response.status === 401 && config && !config.__isRetryRequest && config.url !== TOKENS_API.getToken) {
    originalRequest._retry = true
    hhm.interceptors.response.eject(hhmResponseInterceptor)
    return hhm
      .post(TOKENS_API.refreshToken, { refreshToken: localStorage.getItem('refreshToken') })
      .then((response) => {
        if (response.status === 201) {
          const { accessToken } = response.data
          localStorage.setItem('userToken', accessToken)

          return hhm(originalRequest)
        }
      })
      .then((result) => {
        hhm.interceptors.response.use((response) => response, interceptorError)
        return result
      })
      .catch((error) => {
        return Promise.reject(error)
      })
  }

  if (error?.response?.data?.message) {
    error.message = error?.response?.data?.message
  }

  return Promise.reject(error)
}

const hhmResponseInterceptor = hhm.interceptors.response.use((response) => response, interceptorError)

export { hhm, API_BASE_URL }

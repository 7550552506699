import React from 'react'
import Container from './Container'
import SelectTypeBlock from './SelectTypeBlock'
import HtmlBlock from './HtmlBlock'
import ImageBlock from './ImageBlock'
import VideoBlock from './VideoBlock'
import { BlockItem, BlockType } from '../types'

type PropTypes = {
    block: BlockItem
    onEdit: () => void
    onDelete: () => void
    onSetType: (type: BlockType) => void
}

const Block = ({ block, onEdit, onDelete, onSetType }: PropTypes) => {
    return (
        <Container onEdit={onEdit} onDelete={onDelete} isEditable={block.type !== null}>
            {block.type === BlockType.html && <HtmlBlock html={block?.content || ''} />}

            {block.type === BlockType.image && <ImageBlock src={block.content} />}

            {block.type === BlockType.video && <VideoBlock url={block.content} />}

            {block.type === null && <SelectTypeBlock onSetType={onSetType} />}
        </Container>
    )
}

export default Block

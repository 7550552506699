import React, { useCallback, useState } from 'react'
import { Button } from '@mui/material'
import { Delete } from '@mui/icons-material'
import ConfirmModal from './ConfirmModal'
import { useStateCallback } from '../common/hooks'

export const useDeleteControllers = () => {
    const [openedDeleteConfirm, setOpenedDeleteConfirm] = useStateCallback(false)
    const [deleteItem, setDeleteItem] = useState<string>('')

    const openDeleteModal = useCallback(
        (id: string) => {
            setOpenedDeleteConfirm(true, () => {
                setDeleteItem(id)
            })
        },
        [deleteItem],
    )

    const confirmDelete = useCallback(
        (action: (id: string) => void) => {
            if (deleteItem) {
                setOpenedDeleteConfirm(false, () => {
                    setDeleteItem('')
                })
                action(deleteItem)
            }
        },
        [deleteItem],
    )

    const closeConfirm = () => {
        setOpenedDeleteConfirm(false)
    }

    return {
        openedDeleteConfirm,
        openDeleteModal,
        closeConfirm,
        confirmDelete,
    }
}

type PropTypes = {
    open: boolean
    title?: string
    message?: string
    okButtonText?: string
    onCancel: () => void
    onConfirm: () => void
}

const DeleteConfirmModal = ({
    open,
    onConfirm,
    onCancel,
    message = 'Are you sure want to delete category?',
    okButtonText = 'Delete Category',
    title = 'Delete Category',
}: PropTypes) => {
    return (
        <ConfirmModal
            open={open}
            title={title}
            message={message}
            subtext="You can’t undo this operation."
            onCancel={onCancel}
            okButton={
                <Button variant="outlined" size="large" startIcon={<Delete />} sx={{ mr: 1 }} onClick={onConfirm}>
                    {okButtonText}
                </Button>
            }
        />
    )
}

export default DeleteConfirmModal

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import CMS_API from './cmsApi'
import { BlockType } from '../../components/EditContent/types'
import { resetAll } from '../common/commonSlice'

export type BlockItem = { type: BlockType | null; id: string; content?: string }

export interface Categories {
  id: string
  title: string
  description: string
  pagesCount?: number
}

export type Page = {
  id: string
  categoryId: string
  title: string
  keywords: string
  description: string
  banner: string | null
  url: string
  fullUrl: string
  content?: {
    blocks: BlockItem[][]
  }
}

export interface CategoryState {
  loading: boolean
  categories: Categories[]
  pages: Page[]
  error: string | undefined
}
const initialState: CategoryState = {
  loading: false,
  categories: [],
  pages: [],
  error: undefined,
}

export const fetchCategories = createAsyncThunk('cms/fetchCategories', async () => {
  const { data } = await CMS_API.getCategories()
  return data
})

export const fetchPages = createAsyncThunk('cms/fetchPages', async () => {
  const { data } = await CMS_API.getPages()
  return data
})

export const updatePage = createAsyncThunk('cms/updatePage', async (params: { id: string; data: Page }) => {
  const { id, data } = params
  await CMS_API.updatePage(id, data)
})

const cmsSlice = createSlice({
  name: 'cms',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState)
    // Categories flow
    builder.addCase(fetchCategories.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchCategories.fulfilled, (state, action: PayloadAction<Categories[]>) => {
      state.loading = false
      state.categories = action.payload
    })
    builder.addCase(fetchCategories.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    //Pages flow
    builder.addCase(fetchPages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchPages.fulfilled, (state, action: PayloadAction<Page[]>) => {
      state.loading = false
      state.pages = action.payload
    })
    builder.addCase(fetchPages.rejected, (state, action) => {
      state.loading = false
      state.error = action.error.message
    })

    // Update user flow
    builder.addCase(updatePage.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updatePage.fulfilled, (state) => {
      state.pages = []
    })
    builder.addCase(updatePage.rejected, (state) => {
      state.loading = false
    })
  },
  reducers: {},
})

export default cmsSlice.reducer

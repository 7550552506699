import React from 'react'
import { Typography } from '@mui/material'
import { CookieSettings } from '../types'

const CookieSettingBlock = ({ data }: { data: CookieSettings }) => {
    return (
        <>
            <Typography variant="body1">Title:</Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                {data.title}
            </Typography>
            <Typography variant="body1">Message:</Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                <span dangerouslySetInnerHTML={{ __html: data.message }} />
            </Typography>
            <Typography variant="body1">Confirm button:</Typography>
            <div>
                <Typography variant="body2">
                    Status:{' '}
                    <span style={{ color: data.acceptButtonEnable === 'on' ? 'green' : 'red' }}>
                        {data.acceptButtonEnable}
                    </span>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Title: <span>{data.acceptButtonText}</span>
                </Typography>
            </div>
            <Typography variant="body1">Decline button:</Typography>
            <div>
                <Typography variant="body2">
                    Status:{' '}
                    <span style={{ color: data.declineButtonEnable === 'on' ? 'green' : 'red' }}>
                        {data.declineButtonEnable}
                    </span>
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    Title: <span>{data.declineButtonText}</span>
                </Typography>
            </div>
        </>
    )
}

export default CookieSettingBlock

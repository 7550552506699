import React, { useState } from 'react'
import ListItemText from '@mui/material/ListItemText'
import { IconButton, ListItem, ListItemAvatar } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import { ResourceItem } from '../../../../features/resources/resourcerSlice'

const st = {
    listItem: {
        bgcolor: 'background.paper',
        marginBottom: '12px',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '56px',
        cursor: 'pointer',
    },
    itemAvatar: {
        margin: '6px 10px 14px 24px',
        minWidth: 'auto',
    },
    listItemText: {
        '& .MuiListItemText-primary': {
            fontWeight: 'bold',
            textOverflow: 'ellipsis',
        },
        '& .MuiListItemText-secondary': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
    },
    itemIcon: { height: '54px', width: '54px', color: '#F15E75' },
}

export interface Resource extends Partial<ResourceItem> {}

type PropTypes = {
    row: any
    onEdit: (a: any) => void
    onDelete?: (a: any) => void
    onClick: (row: Resource) => void
    numeric?: boolean
    noSubtext?: boolean
    index: number
}

const RowItem = ({ row, onEdit, onDelete, index, onClick, numeric = true, noSubtext = false }: PropTypes) => {
    const [hovered, setHovered] = useState(false)

    return (
        <ListItem
            sx={st.listItem}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => onClick(row)}
        >
            <div style={{ display: 'flex', maxWidth: 'calc(100% - 96px)' }}>
                {numeric && <ListItemAvatar sx={st.itemAvatar}>{index}.</ListItemAvatar>}
                <ListItemText
                    primary={row.title}
                    secondary={row.description || (noSubtext ? '' : 'ㅤ')}
                    sx={st.listItemText}
                />
            </div>
            <div style={{ display: 'flex', minWidth: '96px', flexDirection: 'row-reverse' }}>
                <div style={{ display: hovered ? 'block' : 'none' }}>
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            onEdit(row.id)
                        }}
                    >
                        <Edit />
                    </IconButton>
                    {typeof onDelete === 'function' && (
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation()
                                onDelete(row.id)
                            }}
                        >
                            <Delete />
                        </IconButton>
                    )}
                </div>
            </div>
        </ListItem>
    )
}

export default RowItem

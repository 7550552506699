import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import ModalWindow from './ModalWindow'

const st = {
    blockButtons: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'right', mt: 3 },
}

type PropTypes = {
    title: string
    message: string
    subtext?: string
    okButton?: React.ReactNode
    cancelButton?: React.ReactNode
    open: boolean
    onCancel: () => void
    onConfirm?: () => void
}

const ConfirmModal = ({
    title,
    open = false,
    message,
    subtext,
    onCancel,
    okButton,
    onConfirm,
    cancelButton,
}: PropTypes) => {
    return (
        <ModalWindow title={title} open={open} onClose={onCancel}>
            <Box sx={{ mt: 4, mb: 4 }}>
                <Typography variant="body1">{message}</Typography>
                {subtext && (
                    <Typography variant="body2" sx={{ color: '#9F9FA8' }}>
                        {subtext}
                    </Typography>
                )}
            </Box>
            <Box sx={st.blockButtons}>
                {!!okButton ? (
                    okButton
                ) : (
                    <Button variant="outlined" size="large" sx={{ mr: 1 }} onClick={onConfirm}>
                        Ok
                    </Button>
                )}
                {!!cancelButton ? (
                    cancelButton
                ) : (
                    <Button variant="contained" size="large" onClick={onCancel}>
                        Cancel
                    </Button>
                )}
            </Box>
        </ModalWindow>
    )
}

export default ConfirmModal

import React from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { AuthProvider } from './contexts/auth'
import theme from './Theme'
import Content from './Content'
import NotAuthenticatedContent from './NotAuthenticatedContent'
import AdminLayout from './layouts/admin'
import { useAuth } from './contexts/auth'
import { NavigationProvider } from './contexts/navigation'
import LoadingBackdrop from './components/LoadingBackdrop'
import SystemMessage from './components/SystemMessage'

const App = () => {
    const { user } = useAuth()

    if (user.loggedIn) {
        return (
            <AdminLayout user={user} >
                <Content user={user} />
            </AdminLayout>
        )
    }

    return <NotAuthenticatedContent />
}

export default () => {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AuthProvider>
                    <NavigationProvider>
                        <>
                            <CssBaseline />
                            <App />
                            <LoadingBackdrop />
                            <SystemMessage />
                        </>
                    </NavigationProvider>
                </AuthProvider>
            </Router>
        </ThemeProvider>
    )
}

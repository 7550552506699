import React from 'react'
import { Error } from '@mui/icons-material'
import { Typography, Alert } from '@mui/material'

type PropTypes = {
    message: string | null
}

const FormErrorMessage = ({ message }: PropTypes) => {
    return (
        <Alert severity="error" icon={<Error />} sx={{ display: !!message ? null : 'none', mt: 6, width: '100%' }}>
            <Typography variant="body1">Error</Typography>
            <Typography variant="body2">{message}</Typography>
        </Alert>
    )
}

export default FormErrorMessage

import React, { useEffect } from 'react'
import { Box, Button, Paper, Typography } from '@mui/material'
import PageDetails from './components/PageDetails'
import ErrorsAlert, { ErrorsList } from '../../../components/ErrorsAlert'
import { useLocation, useNavigate } from 'react-router-dom'
import { BlockItem, Page, updatePage } from '../../../features/cms/cmsSlice'
import { useAsyncRequestDispatch } from '../../../store'
import { mapId, validate } from '../../../components/EditContent/helpers'
import EditContent from '../../../components/EditContent/EditContent'
import EditPageModal, { usePageModal } from './components/EditPageModal'
import { usePages } from '../../../features/cms/cmsHooks'

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

const CmsPageEdit = () => {
    const { state } = useLocation()
    const asyncDispatch = useAsyncRequestDispatch()
    const pageId = state?.page?.id
    const navigate = useNavigate()
    const [formError, setFormError] = React.useState<ErrorsList>([])
    const { pages } = usePages()
    const { open, page, onUpdatePage, openModal, onCloseModal } = usePageModal({})
    const [blocks, setBlocks] = React.useState<BlockItem[][]>([])
    useEffect(() => {
        const currentPage = pages.find((i) => i.id === pageId)
        if (currentPage) {
            onUpdatePage(currentPage)
            if (currentPage?.content?.blocks) {
                setBlocks(mapId(currentPage.content.blocks || []))
            }
        }
    }, [pages])

    const handleChangeBlocks = (blocks: BlockItem[][]) => {
        setBlocks(blocks)
    }

    const handleSavePage = (): void => {
        const { result, errors } = validate({ content: { blocks } })
        if (errors) {
            setFormError(errors)
            return
        }

        setFormError([])
        asyncDispatch(updatePage({ id: page.id as string, data: result }), 'Page updated').then()
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap}} mb={3}>
                <Typography variant="subtitle2" onClick={() => navigate(-1)} className="breadcrumbs">
                    Back to Pages
                </Typography>
                <Box>
                    <Button size="large" variant="contained" onClick={handleSavePage}>
                        Save Page
                    </Button>
                </Box>
            </Box>
            <ErrorsAlert errors={formError} />
            <Paper elevation={0} sx={{ p: 3, mb: 4 }}>
                <PageDetails pageData={page as Page} onOpenEdit={() => openModal(page)} />
            </Paper>
            <EditContent blocks={blocks} onChange={handleChangeBlocks} />
            <EditPageModal page={page} onClose={onCloseModal} open={open} />
        </>
    )
}

export default CmsPageEdit

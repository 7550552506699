import React from 'react'
import { Typography } from '@mui/material'

type PropTypes = {
    url?: string
    title?: string
}

const VideoBlock = ({ url }: PropTypes) => {
    if (url) {
        return <iframe src={url} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" title="Your video" />
    }

    return <Typography>No video</Typography>
}

export default VideoBlock

import React, { useMemo, useState } from 'react'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Button, Paper } from '@mui/material'
import { Add } from '@mui/icons-material'
import Box from '@mui/material/Box'
import { useUsers } from '../../features/users/usersHooks'
import RowActions from './components/RowActions'
import DeleteConfirmModal, { useDeleteControllers } from '../../components/DeleteConfirmModal'
import { useAppDispatch, useAsyncRequestDispatch } from '../../store'
import { deleteUser } from '../../features/users/usersSlice'
import CreateOrUpdateUserModal, { useCreateOrUpdateUser } from './components/CreateOrUpdateUserModal'
import { prettifyUserRoles } from '../../common/helpers'

export default function Users() {
    const dispatch = useAppDispatch()
    const asyncDispatch = useAsyncRequestDispatch()
    const users = useUsers()
    const [isEdit, setIsEdit] = useState(false)
    const { openedDeleteConfirm, openDeleteModal, closeConfirm, confirmDelete } = useDeleteControllers()
    const { openedModal, openUserModal, onClose: onCloseUserModal, user: editedUser } = useCreateOrUpdateUser()

    const handleInviteUser = () => {
        setIsEdit(false)
        openUserModal({})
    }

    const handleEditUser = (id: string) => {
        const user = users.find((i) => i.id === id)
        setIsEdit(true)
        if (user) {
            openUserModal(user)
        }
    }

    const handleDeleteUser = (id: string) => {
        openDeleteModal(id)
    }

    const onDeleteConfirm = async (id: string) => {
        await asyncDispatch(deleteUser({ id }), 'User deleted')
    }

    const cellsConfig = useMemo((): GridColDef[] => {
        return [
            { field: 'name', headerName: 'First name', width: 230 },
            { field: 'surname', headerName: 'Last name', width: 230 },
            { field: 'email', headerName: 'E-mail', width: 250 },
            {
                field: 'role',
                headerName: 'Role',
                width: 230,
                valueGetter: ({ row }) => prettifyUserRoles(row.roles),
            },
            {
                field: 'isVerified',
                headerName: 'Status',
                valueGetter: ({ row }) => (row.isVerified ? 'Active' : 'Invited'),
                renderCell: (params) => (
                    <span style={{ color: params.value === 'Active' ? '#4CAF50' : '#FF9800' }}>{params.value}</span>
                ),
            },
            {
                field: 'id',
                headerName: '',
                renderCell: (params) => (
                    <RowActions value={params.value} onEdit={handleEditUser} onDelete={handleDeleteUser} />
                ),
                sortable: false,
                filterable: false,
            },
        ]
    }, [users])

    return (
        <div style={{ width: '100%' }}>
            <Paper>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} pr={2}>
                    <Button
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, mb: 3 }}
                        startIcon={<Add />}
                        onClick={handleInviteUser}
                    >
                        Invite User
                    </Button>
                </Box>
                <DataGrid
                    rows={users}
                    columns={cellsConfig}
                    initialState={{}}
                    pageSizeOptions={[5, 10]}
                    sx={{
                        '& .MuiDataGrid-columnHeader': {
                            color: '#9F9FA8',
                        },
                    }}
                    hideFooter
                />
            </Paper>
            <DeleteConfirmModal
                title="Delete User"
                message="Are you sure want to delete user?"
                okButtonText="Delete User"
                open={openedDeleteConfirm}
                onConfirm={() => confirmDelete(onDeleteConfirm)}
                onCancel={closeConfirm}
            />
            <CreateOrUpdateUserModal open={openedModal} user={editedUser} isEdit={isEdit} onClose={onCloseUserModal} />
        </div>
    )
}

import React from 'react'
import { Box } from '@mui/material'
import { Add } from '@mui/icons-material'
import { AddPosition } from '../types'
import st from '../styles'

type PropTypes = {
    isAdd: boolean
    onAddBlock: (pos: AddPosition) => void
    children?: React.ReactNode
}

const Row = ({ isAdd, onAddBlock, children }: PropTypes) => {
    const addButtonsStyle = !isAdd ? { ...st.addBlock, opacity: 0.4, cursor: 'auto' } : st.addBlock

    const handleAddBlock = (position: AddPosition) => {
        if (!isAdd) {
            return
        }

        onAddBlock(position)
    }

    return (
        <Box sx={st.rowBlock}>
            <Box sx={{ ...addButtonsStyle, mr: 1 }} onClick={() => handleAddBlock(AddPosition.before)}>
                <Add sx={st.addButton} />
            </Box>
            {children}
            <Box sx={{ ...addButtonsStyle, ml: 1 }} onClick={() => handleAddBlock(AddPosition.after)}>
                <Add sx={st.addButton} />
            </Box>
        </Box>
    )
}

export default Row

import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import RESOURCE_API from '../../../features/resources/resourcesApi'
import EditResourceForm from './components/EditResourceForm'
import { BlockItem } from '../../../components/EditContent/types'
import { mapId, validate } from '../../../components/EditContent/helpers'
import ErrorsAlert, { ErrorsList } from '../../../components/ErrorsAlert'
import { addOrUpdateResource } from '../../../features/resources/resourcerSlice'
import { useAsyncRequestDispatch } from '../../../store'

export type TagItem = {
    id: string
    title: string
}

export interface Resource {
    id?: string
    title: string
    keywords?: string
    description?: string
    tags: TagItem[]
    content: { blocks: BlockItem[][] }
}

const st = {
    boxWrap: { display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' },
}

const EditResource = () => {
    const { id } = useParams()
    const [resource, setResource] = useState()
    const formRef = useRef(null)

    const asyncDispatch = useAsyncRequestDispatch()
    const navigate = useNavigate()
    const [formError, setFormError] = React.useState<ErrorsList>([])

    useEffect(() => {
        !!id && handleSave(id)
    }, [id])

    // Main data saved over modal windoow
    const handleSave = (id: string) => {
        RESOURCE_API.getResourceById(id).then(({ data }) => {
            setResource({ ...data, content: { ...data.content, blocks: mapId(data.content?.blocks ?? []) } })
        })
    }

    const handleUpdate = () => {
        if (typeof formRef.current !== 'function') {
            return
        }
        // @ts-ignore
        const blocks = formRef.current()
        const { result, errors } = validate({ content: { blocks } })
        if (errors) {
            setFormError(errors)
            return
        }
        setFormError([])
        asyncDispatch(addOrUpdateResource({ resource: { id, ...result } as Resource }), 'Article updated').then()
    }

    if (!resource) {
        return null
    }

    return (
        <>
            <Box sx={{ ...st.boxWrap, mt: 3 }}>
                <Typography variant="subtitle2" onClick={() => navigate(-1)} className="breadcrumbs">
                    Back to Articles
                </Typography>

                <Box sx={{ mt: 1, mb: 4 }}>
                    <Button size="large" variant="contained" onClick={handleUpdate}>
                        Save Article
                    </Button>
                </Box>
            </Box>
            <ErrorsAlert errors={formError} />
            {resource && <EditResourceForm resource={resource} onSave={handleSave} ref={formRef} />}
        </>
    )
}

export default EditResource

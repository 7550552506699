import { useAppDispatch, useAppSelector } from '../../store'
import { useEffect } from 'react'
import { fetchMarkets } from './marketsSlice'

export const useMarkets = () => {
  const dispatch = useAppDispatch()
  const { markets, picked } = useAppSelector((state) => state.markets)

  useEffect(() => {
    if (!markets.length) {
      dispatch(fetchMarkets())
    }
  }, [])

  return { markets, picked }
}
